import { Article, ArticleResolution, ArticleType } from '@wix/answers-api';
import { isLong } from './isLong';
import Long from 'long';
import moment from 'moment';

export const isMonthOldLaunchedFr = (article: Article) => {
  if (article.type === ArticleType.FEATURE_REQUEST && article.resolution === ArticleResolution.RELEASED) {
    const dateInUse = article.lastUpdateDate || article.creationDate;
    const isDateLong = isLong(dateInUse);
    const lastPublishedDateNormalized = !isDateLong ? dateInUse : new Long(dateInUse.low, dateInUse.high).toNumber();
    const articleDate = moment.unix(Math.floor(lastPublishedDateNormalized / 1000)).toDate();
    const currentDate = new Date();
    const monthAgo = new Date(currentDate.setMonth(currentDate.getMonth() - 1));
    return articleDate < monthAgo;
  } else {
    return false;
  }
};
