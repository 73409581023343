import React, { FunctionComponent, useEffect, useState } from 'react';
import { useRouter } from 'next/router';
import { Loader } from '../Loader';

export const GlobalLoader: FunctionComponent = () => {
  const [isLoading, setIsLoading] = useState(false);
  const router = useRouter();

  useEffect(() => {
    const handleRouteChange = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setIsLoading(true);
      }
    };

    const handleRouteChangeComplete = (url: string, { shallow }: { shallow: boolean }) => {
      if (!shallow) {
        setIsLoading(false);
      }
    };


    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);
  return <Loader isLoading={isLoading} />;
};
