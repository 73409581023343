import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
  useState,
} from 'react';
import { Notification, Text } from '@wix/design-system';
import { DATA_HOOKS } from '../../../dataHooks';
import { RenderHTML } from '../../RenderHTML';
import messagesHE from '../../../locales/messages_he.json';
import { LOCALES } from '../../../constants';
import {
  isNotificationShowed,
  setNotification,
} from '../../../utils/notification';
import { useRouter } from 'next/router';
import css from './index.module.scss';
import {
  helpCenterBannerView,
  helpCenterBannerClick,
} from '@wix/bi-logger-new-help-center/v2';
import { useBI } from '../../../hooks/useBI';
import { Context } from '../../../context';
import { mapPageTypeToSourceName } from '../../../utils/bi';
import { PageType } from '../../../types';
import { getWixLanguage } from '../../../utils/login';

const suggestedSupportedRegions = {
  'Asia/Jerusalem': {
    value: (
      <Text dir="rtl" light>
        <RenderHTML
          className={css.hebrewText}
          html={messagesHE['notification.bar.lang.supported']}
        />
      </Text>
    ),
    id: 'suggestedSupportedRegions_he',
    text: messagesHE['notification.bar.lang.supported'],
    languagesDislayList: [LOCALES.EN],
    targetLocale: LOCALES.HE,
  },
};

type LanguageSupportedNotificationProps = {
  pageType: PageType;
};

export const LanguageSupportedNotification: FunctionComponent<
  LanguageSupportedNotificationProps
> = ({ pageType }) => {
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();
  const { pageTemplateData } = useContext(Context);
  const [isDisplay, setIsDisplay] = useState<boolean>(false);
  const { isWixEmployee } = useContext(Context);
  const regionTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
  const suggestedItem =
    suggestedSupportedRegions[
      regionTimeZone as keyof typeof suggestedSupportedRegions
    ];

  const sendViewBannerEvent = useCallback(async () => {
    await sendBIEvent(
      helpCenterBannerView({
        banner_text: suggestedItem.text,
        banner_language: suggestedItem.targetLocale,
        banner_name: suggestedItem.id,
        item_id: pageTemplateData.itemId,
        item_type: pageTemplateData.itemType,
        kb_lang: locale,
        source_name: mapPageTypeToSourceName(pageType),
        has_cta: true,
      })
    );
  }, [pageTemplateData]);

  const sendClickBannerEvent = useCallback(
    async (type: string, clickedText?: string, url?: string) => {
      await sendBIEvent(
        helpCenterBannerClick({
          banner_text: suggestedItem.text,
          banner_language: suggestedItem.targetLocale,
          banner_name: suggestedItem.id,
          clicked_item_type: type,
          clicked_text: clickedText,
          clicked_url: url,
          item_id: pageTemplateData.itemId,
          item_type: pageTemplateData.itemType,
          kb_lang: locale,
          source_name: mapPageTypeToSourceName(pageType),
        })
      );
    },
    [pageTemplateData]
  );

  useEffect(() => {
    const currertUserLanguage = getWixLanguage();

    if (
      suggestedItem &&
      currertUserLanguage &&
      !isNotificationShowed(suggestedItem.id) &&
      suggestedItem.targetLocale !== locale &&
      suggestedItem.languagesDislayList?.includes(currertUserLanguage)
    ) {
      setIsDisplay(true);
      void sendViewBannerEvent();
    }
  }, [locale, sendViewBannerEvent, suggestedItem]);

  if (isWixEmployee) {
    return null;
  }

  return (
    <div className={css.notification}>
      <Notification
        dataHook={DATA_HOOKS.LANGUAGE_SUPPORTED_NOTIFICATION}
        show={isDisplay}
        onClose={() => {
          setIsDisplay(false);
          setNotification(suggestedItem.id);
          void sendClickBannerEvent('close');
        }}
      >
        <Notification.TextLabel ellipsis={false} textAlign="center">
          <div
            onClick={({ target }: { target: any }) =>
              target?.tagName === 'A'
                ? sendClickBannerEvent('link', target?.innerHTML, target?.href)
                : undefined
            }
          >
            {suggestedItem && suggestedItem.value}
          </div>
        </Notification.TextLabel>
        <Notification.CloseButton className={css.closeBtn} />
      </Notification>
    </div>
  );
};
