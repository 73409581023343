import React, { FunctionComponent } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  Box,
  DropdownLayoutValueOption,
  FormField,
  Text,
  MultiSelectCheckbox,
  Input,
} from '@wix/design-system';
import css from './index.module.scss';
import { ArticleSortType } from '@wix/answers-api';
import { ALL_PRODUCTS_LABEL_ID } from '../../../../constants';
import { DATA_HOOKS } from '../../../../dataHooks';
import { RoadmapFilter, SortAndFilterBiMetaData } from '../../../../types';
import { Search } from '@wix/wix-ui-icons-common';

export type RoadmapSelectProductsProps = {
  roadmapFilter: RoadmapFilter;
  onChangeFilter: (filter: RoadmapFilter) => void;

  productLabels: { id: string; name: string }[];
  onChangeTriggerBiMetaData: (
    triggerBiMetaData: SortAndFilterBiMetaData
  ) => void;
};
export const ALL_SORT_OPTION = [
  ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ArticleSortType.VOTES_DESC,
];

export const RoadmapSelectProducts: FunctionComponent<
  RoadmapSelectProductsProps
> = ({
  productLabels,
  roadmapFilter,
  onChangeFilter,
  onChangeTriggerBiMetaData,
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = React.useState('');

  const selectedOptionsLabels = React.useMemo(
    () =>
      (roadmapFilter.labels.length === 1 &&
        roadmapFilter.labels[0].id === ALL_PRODUCTS_LABEL_ID) ||
      !roadmapFilter.labels
        ? []
        : roadmapFilter.labels,
    [roadmapFilter.labels]
  );

  const allProductOptions = productLabels.map((productLabel) => ({
    id: productLabel.id,
    label: productLabel.name,
    value: productLabel.name,
  }));

  const onSelectProduct = React.useCallback(
    (id: string | number) => {
      const productName = productLabels.find((label) => label.id === id)?.name;
      onChangeTriggerBiMetaData({ filter_by: productName });
      if (id === ALL_PRODUCTS_LABEL_ID) {
        onChangeFilter({
          ...roadmapFilter,
          labels: productLabels,
        });
      } else {
        onChangeFilter({
          ...roadmapFilter,
          labels: productLabels.filter(
            (option) =>
              selectedOptionsLabels.map((p) => p.id).includes(option.id) ||
              option.id === id
          ),
        });
      }
    },
    [onChangeFilter, selectedOptionsLabels]
  );

  const onDeselectProduct = React.useCallback(
    (id: string | number) => {
      if (id === ALL_PRODUCTS_LABEL_ID) {
        onChangeFilter({ ...roadmapFilter, labels: [] });
      } else {
        const updatedFilteredLabels = roadmapFilter.labels.filter(
          (label) => label.id !== ALL_PRODUCTS_LABEL_ID && label.id !== id
        );
        onChangeFilter({
          ...roadmapFilter,
          labels: updatedFilteredLabels,
        });
      }
    },
    [onChangeFilter, selectedOptionsLabels]
  );

  const valueForSelectProduct = (selectedCount: number) => {
    const isAllProductSelected =
      selectedOptionsLabels
        .map((selectedLabel) => selectedLabel.id)
        .includes(ALL_PRODUCTS_LABEL_ID) ||
      selectedCount === productLabels.length;

    if (selectedCount === 0) {
      return '';
    } else if (isAllProductSelected) {
      return t('roadmap.select.product.value.all-product');
    } else if (selectedCount === 1) {
      return t('roadmap.select.product.singular.value');
    } else {
      return t('roadmap.select.product.value', {
        number: selectedCount,
      });
    }
  };

  const onClear = () => {
    onDeselectProduct(ALL_PRODUCTS_LABEL_ID);
    setSearchValue('');
  };

  const predicate = (option: DropdownLayoutValueOption) =>
    (option.value as string).toLowerCase().includes(searchValue.toLowerCase());

  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value.slice(0, 20);
    setSearchValue(value);
  };

  const emptyStateMessage = (
    <Text size="small" weight="thin" className={css.emptyStateMessage}>
      {t('roadmap.select-product.empty-state-message')}
    </Text>
  );

  return (
    <Box className={css.RoadmapSelectProducts}>
      <FormField
        dataHook={DATA_HOOKS.ROADMAP_SELECT_PRODUCTS_WRAPPER}
        label={t('roadmap.product-header')}
      >
        <MultiSelectCheckbox
          value={searchValue}
          prefix={
            <Input.IconAffix>
              <Search />
            </Input.IconAffix>
          }
          options={allProductOptions}
          selectedOptions={selectedOptionsLabels.map((l) => l.id)}
          border="round"
          dataHook={DATA_HOOKS.ROADMAP_SELECT_PRODUCTS}
          onClear={onClear}
          onSelect={onSelectProduct}
          onDeselect={onDeselectProduct}
          placeholder={t('roadmap.select-product.placeholder')}
          onChange={onChange}
          predicate={predicate}
          enableSearch
          getSelectedOptionsText={valueForSelectProduct}
          emptyStateMessage={emptyStateMessage}
        />
      </FormField>
    </Box>
  );
};
