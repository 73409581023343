import { useContext } from 'react';
import { Context } from '../context';
import { ArticleSearchResultItem, PageType } from '../types';
import { useBI } from './useBI';
import { voteUnVoteForWishList } from '@wix/bi-logger-new-help-center/v2';
import { BI } from '../constants';

export enum OptInModalStatus {
  Confirm = 'confirm',
  OptInFailed = 'optInFailed',
  OptOutFailed = 'optOutFailed',
  Closed = 'closed',
}

type OptInModalBiData = {
  articleId: string;
  locale: string;
};

const useOptInModalBi = (data: OptInModalBiData) => {
  const { sendBIEvent } = useBI();
  const context = useContext(Context);

  const commonData = {
    source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
    kb_lang: data.locale,
    item_id: data.articleId,
    vote_type: BI.VOTE_TYPE.FEATURE_REQUEST_ENROLLMENT,
  };

  const optInSucceeded = () => {
    void sendBIEvent(
      voteUnVoteForWishList({
        ...commonData,
        thumbs_up: true,
      })
    );
  };

  const optOutSucceeded = () => {
    void sendBIEvent(
      voteUnVoteForWishList({
        ...commonData,
        thumbs_up: false,
      })
    );
  };

  return { optInSucceeded, optOutSucceeded };
};


export const useOptInUtils = (article: ArticleSearchResultItem, locale: string, togglePopulationStatus: (featureId: string) => void, setOptInModalStatus: (optInModalStatus: OptInModalStatus) => void, setShowVoteContent: (value: boolean) => void) => {
  const context = useContext(Context);
  const { answersApi } = context;
  const biLogger = useOptInModalBi({ articleId: article.id, locale });

  const optIn = async () => {
    if (!article.featureEnrollment?.featureId) { return; }
    setOptInModalStatus(OptInModalStatus.Closed);
    try {
      await answersApi.kb.joinPopulation({ populationId: article.featureEnrollment.featureId });
    } catch (e) {
      setOptInModalStatus(OptInModalStatus.OptInFailed);
      return;
    }
    try {
      await answersApi.kb.optInFeature({ id: article.id, locale });
      biLogger.optInSucceeded();
      setShowVoteContent(true);
      togglePopulationStatus(article.featureEnrollment.featureId);
    } catch (e) {
      setOptInModalStatus(OptInModalStatus.OptInFailed);
      await answersApi.kb.leavePopulation({ populationId: article.featureEnrollment.featureId });
      return;
    }
  };

  const optOut = async () => {
    if (!article.featureEnrollment?.featureId) { return; }
    setOptInModalStatus(OptInModalStatus.Closed);
    try {
      await answersApi.kb.leavePopulation({ populationId: article.featureEnrollment.featureId });
    } catch (e) {
      setOptInModalStatus(OptInModalStatus.OptOutFailed);
      return;
    }
    try {
      await answersApi.kb.optOutFeature({ id: article.id, locale });
      biLogger.optOutSucceeded();
      setShowVoteContent(false);
      togglePopulationStatus(article.featureEnrollment.featureId);
    } catch (e) {
      setOptInModalStatus(OptInModalStatus.OptOutFailed);
      await answersApi.kb.joinPopulation({ populationId: article.featureEnrollment.featureId });
      return;
    }
  };

  return { optIn, optOut };
};
