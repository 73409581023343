import React, { FunctionComponent, useContext } from 'react';
import { ToggleButton } from '@wix/design-system';
import { useRouter } from 'next/router';
import HeartSmall from 'wix-ui-icons-common/HeartSmall';
import HeartFilledSmall from 'wix-ui-icons-common/HeartFilledSmall';
import ThumbUpSmall from 'wix-ui-icons-common/ThumbsUpSmall';
import ThumbUpFilledSmall from 'wix-ui-icons-common/ThumbsUpFilledSmall';
import { ArticleResolution } from '@wix/answers-api';
import { ArticleSearchResultItem } from '../../../../types';
import { Context } from '../../../../context';
import { isMobile } from '../../../../utils/userAgent';
import { COLORS } from './../../../../utils/theme';
import RoadmapVote from '../../RoadmapVote';
import { RoadmapResolutionEta } from '../../../RoadmapResolutionEta';
import css from './index.module.scss';

type RoadmapArticleListItemFooterProps = {
  article: ArticleSearchResultItem;
  followArticle: (articleId: string) => void;
  unFollowArticle: (articleId: string) => void;
  isFollowing: boolean;
  onClickStatus: (resolution: ArticleResolution) => void;
  togglePopulationStatus?: (featureId: string) => void;
  articlePopulationStatus?: Record<string, boolean>;
};
export const LABELS_LENGTH = 3;

export const MAX_PREVIEW_SIZE = 300;

export const RoadmapArticleListItemFooter: FunctionComponent<
  RoadmapArticleListItemFooterProps
> = ({
  article,
  followArticle,
  unFollowArticle,
  isFollowing,
  onClickStatus,
  togglePopulationStatus,
  articlePopulationStatus,
}: RoadmapArticleListItemFooterProps) => {
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { locale } = useRouter();

  const clickFollowArticle = () => followArticle(article.id);
  const clickUnFollowArticle = () => unFollowArticle(article.id);

  const mobileHeartIcon = isFollowing ? (
    <HeartFilledSmall color={COLORS.B10} />
  ) : (
    <HeartSmall color={COLORS.D10} className={css.mobileHeartIcon} />
  );

  const thumbUpIcon = isFollowing ? (
    <ThumbUpFilledSmall color={COLORS.B10} />
  ) : (
    <ThumbUpSmall color={COLORS.D10} className={css.mobileHeartIcon} />
  );

  const mobileFollowButton =
    articlePopulationStatus && togglePopulationStatus ? (
      <RoadmapVote
        article={article}
        followArticle={clickFollowArticle}
        unFollowArticle={clickUnFollowArticle}
        locale={locale || 'en'}
        articlePopulationStatus={articlePopulationStatus}
        togglePopulationStatus={togglePopulationStatus}
        isFollowingArticle={isFollowing}
      />
    ) : (
      <ToggleButton
        className={
          isFollowing ? css.mobileFollowedButton : css.mobileFollowButton
        }
        onClick={isFollowing ? clickUnFollowArticle : clickFollowArticle}
        size="small"
        selected={isFollowing}
        shape="round"
        border={true}
        labelPlacement="end"
      >
        {article.resolution === ArticleResolution.COLLECTING_VOTES
          ? thumbUpIcon
          : mobileHeartIcon}
      </ToggleButton>
    );

  return (
    <div className={`${css.tagsWrapper}`}>
      <RoadmapResolutionEta
        wrapEtaWithTag={false}
        resolution={article.resolution}
        resolutionEta={article.resolutionEta || undefined}
        onClickStatus={onClickStatus}
      />
      {article.resolution !== ArticleResolution.RELEASED &&
        isMobileView &&
        mobileFollowButton}
    </div>
  );
};
