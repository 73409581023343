export const setSessionStorage = (key: string, value: string) => {
  try {
    sessionStorage?.setItem(key, value);
  } catch (e) {
    /*swallow error*/
  }
};

export const getSessionStorage = (key: string) => {
  try {
    return sessionStorage?.getItem(key);
  } catch (e) {
    /*swallow error*/
  }
};

export const setLocalStorage = (key: string, value: string) => {
  try {
    localStorage?.setItem(key, value);
  } catch (e) {
    /*swallow error*/
  }
};

export const getLocalStorage = (key: string) => {
  try {
    return localStorage?.getItem(key);
  } catch (e) {
    /*swallow error*/
  }
};
