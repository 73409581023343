import React, {
  FunctionComponent,
  KeyboardEvent,
  MouseEvent,
  MouseEventHandler,
} from 'react';
import Router, { useRouter } from 'next/router';
import Link, { LinkProps } from 'next/link';

type InnerLinkProps = {
  children: React.ReactNode;
  className?: string;
  dataHook?: string;
  onClick?: (e: KeyboardEvent | MouseEvent) => void;
  itemProp?: string;
  onMouseLeave?: MouseEventHandler;
  withEnterHandler?: boolean;
  ariaLabel?: string;
} & LinkProps;

export const InnerLink: FunctionComponent<InnerLinkProps> = ({
  children,
  dataHook,
  onClick,
  className,
  itemProp,
  onMouseLeave,
  withEnterHandler,
  ariaLabel,
  ...props
}: InnerLinkProps) => {
  const { locale } = useRouter();
  const customHref = `/${props.locale ? props.locale : locale}${
    props.href && props.href.toString().startsWith('/') ? '' : '/'
  }${props.href}`;

  const onKeyPress = (e: KeyboardEvent<HTMLAnchorElement>) => {
    if (e.key === 'Enter' && withEnterHandler && onClick) {
      onClick(e);
    }
  };

  return (
    <Link
      {...props}
      href={customHref}
      className={className}
      data-hook={dataHook}
      onClick={onClick}
      onKeyPress={onKeyPress}
      itemProp={itemProp}
      prefetch={false}
      onMouseLeave={onMouseLeave}
      aria-label={ariaLabel}
    >
      {children}
    </Link>
  );
};

export const pushUrl = async (
  url: string,
  shallow?: boolean,
  locale?: string
) => {
  const customUrl = `${url.toString().startsWith('/') ? '' : '/'}${url}`;
  await Router.push(customUrl, undefined, { shallow, locale });
};
