/* eslint-disable max-lines */
import React, { FunctionComponent, useContext, useState } from 'react';
import {
  Box,
  CounterBadge,
  CustomModalLayout,
  FormField,
  IconButton,
  Modal,
  RadioGroup,
  Text,
} from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import ChangeOrderSmall from 'wix-ui-icons-common/ChangeOrderSmall';
import css from './index.module.scss';

import { ArticleResolution } from '@wix/answers-api';
import { allFilterOptions } from '..';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import {
  FILTER_RESOLUTIONS,
  RoadmapETAorVotedFilter,
  RoadmapFeatureResolution,
  RoadmapFilter,
  SortAndFilterBiMetaData,
} from '../../../../types';
import { useBI } from '../../../../hooks/useBI';
import { DATA_HOOKS } from '../../../../dataHooks';
import { ALL_PRODUCTS_LABEL_ID, BI, EXPERIMENTS, MOVE_TO_TOP_Z_INDEX } from '../../../../constants';
import { isMobile } from '../../../../utils/userAgent';
import { Context } from '../../../../context';
import { RoadmapSelectProducts } from '../RoadmapSelectProducts';
import { ALL_PRODUCTS_LABEL } from '../../../../pages/roadmap';
import { RoadmapTagSelectedProducts } from '../RoadmapTagsSelectedProducts';
import { WalkthroughPopover } from '../../WalkthroughPopover';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { WalkthroughLevels } from '../..';

export type RoadmapMobileSortAndFilterMenuProps = {
  roadmapFilter: RoadmapFilter;
  onChangeFilter: (filter: RoadmapFilter) => void;

  applyValue: (
    filter: RoadmapETAorVotedFilter,
    resolution?: RoadmapFeatureResolution
  ) => void;

  productLabels: { id: string; name: string }[];
  walkthroughStep?: WalkthroughLevels;
  onChangeWalkthroughStep: (walkthroughStep: WalkthroughLevels | null) => void;
  onChangeTriggerBiMetaData: (
    triggerBiMetaData: SortAndFilterBiMetaData
  ) => void;
};

const RoadmapMobileSortAndFilterMenu: FunctionComponent<
  RoadmapMobileSortAndFilterMenuProps
> = ({
  applyValue,
  roadmapFilter,
  onChangeFilter,
  productLabels,
  walkthroughStep,
  onChangeWalkthroughStep,
  onChangeTriggerBiMetaData,
}: RoadmapMobileSortAndFilterMenuProps) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [selectedResolution, setSelectedResolution] =
    useState<RoadmapFeatureResolution>(roadmapFilter.resolution);
  const [selectedFilter, setSelectedFilter] = useState(
    roadmapFilter.ETAorVoted
  );
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { experiments } = useExperiments({ readOnly: true });

  React.useEffect(() => {
    if (roadmapFilter.resolution !== selectedResolution) {
      setSelectedResolution(roadmapFilter.resolution);
    }
  }, [roadmapFilter.resolution]);
  const resolutionMenu = (
    <Box
      direction="vertical"
      className={css.resolutionMenu}
      paddingBottom={'30px'}
      dataHook={DATA_HOOKS.ROADMAP_MOBILE_STATUS_SELECTOR}
    >
      <FormField label={t('roadmap.resolution-header')}>
        <RadioGroup
          value={selectedResolution}
          onChange={(val) => setSelectedResolution(val as ArticleResolution)}
          spacing={'0'}
        >
          {['all', ...FILTER_RESOLUTIONS].map((res) => (
            <RadioGroup.Radio
              className={css.sortAndFilterOption}
              key={res}
              value={res}
            >
              {t(`roadmap.article-list.resolutions.${res}`)}
            </RadioGroup.Radio>
          ))}
        </RadioGroup>
      </FormField>
    </Box>
  );

  const FilterMenu = (
    <Box
      dataHook={DATA_HOOKS.ROADMAP_MOBILE_FILTER_SELECTOR}
      direction="vertical"
      className={css.filterMenu}
      paddingBottom={'30px'}
    >
      <FormField label={t('roadmap.select-filter.header')}>
        <RadioGroup
          className={css.sortAndFilterOption}
          value={selectedFilter}
          onChange={(val) => setSelectedFilter(val as RoadmapETAorVotedFilter)}
          spacing={'0'}
        >
          {allFilterOptions.map((filter) => (
            <RadioGroup.Radio key={filter} value={filter}>
              {experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT) &&
              filter === RoadmapETAorVotedFilter.FOLLOWING &&
              context.isWixStudioUser
                ? t(`roadmap.filter-option.${filter}.auto-enrollment`)
                : t(`roadmap.filter-option.${filter}`)}
            </RadioGroup.Radio>
          ))}
        </RadioGroup>
      </FormField>
    </Box>
  );

  const onDeselectProduct = React.useCallback(
    (id: string | number) => {
      if (id === ALL_PRODUCTS_LABEL_ID) {
        onChangeFilter({ ...roadmapFilter, labels: [ALL_PRODUCTS_LABEL] });
      } else {
        onChangeFilter({
          ...roadmapFilter,
          labels: roadmapFilter.labels.filter((label) => label.id !== id),
        });
      }
    },
    [onChangeFilter, roadmapFilter.labels]
  );

  const onClear = () => onDeselectProduct(ALL_PRODUCTS_LABEL_ID);

  const openModal = async () => {
    setIsModalOpen(true);
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: 'open_sort_filter_option',
      })
    );
  };

  const closeModal = async () => {
    setIsModalOpen(false);
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: 'close_sort_filter_option',
      })
    );

    if (selectedFilter !== roadmapFilter.ETAorVoted) {
      setSelectedFilter(roadmapFilter.ETAorVoted);
    }
    if (selectedResolution !== roadmapFilter.resolution) {
      setSelectedResolution(roadmapFilter.resolution);
    }
  };

  const apply = () => {
    if (isMobileView) {
      applyValue(selectedFilter, selectedResolution);
    } else {
      applyValue(selectedFilter);
    }
    setIsModalOpen(false);
  };

  const isAllProductOnlySelected =
    roadmapFilter.labels.length === 1 &&
    roadmapFilter.labels[0].id === ALL_PRODUCTS_LABEL_ID;

  const modal = (
    <CustomModalLayout
      dataHook={DATA_HOOKS.FILTER_AND_SORT_MODAL_BODY}
      className={`${css.filterAndSortModalBody} ${
        isMobileView ? css.mobile : ''
      }`}
      primaryButtonText={t('roadmap.mobile.sort-and-filter.apply')}
      primaryButtonOnClick={apply}
      onCloseButtonClick={closeModal}
      primaryButtonProps={{ size: 'medium' }}
      title={
        <Text size="medium" className={css.sortAndFilterModalHeader}>
          {t('roadmap.mobile.sort-filter.side-panel-header')}
        </Text>
      }
      height={'756px'}
      showHeaderDivider={false}
      showFooterDivider={false}
      content={
        <Box direction="vertical">
          <Box direction="vertical" className={css.selectProductWrapper}>
            <RoadmapSelectProducts
              roadmapFilter={roadmapFilter}
              onChangeFilter={onChangeFilter}
              productLabels={productLabels}
              onChangeTriggerBiMetaData={onChangeTriggerBiMetaData}
            />
            <RoadmapTagSelectedProducts
              onClear={onClear}
              onTagRemove={(id) => onDeselectProduct(id)}
              selectedLabels={roadmapFilter.labels}
            />
          </Box>
          {resolutionMenu}
          {FilterMenu}
        </Box>
      }
    />
  );

  const getNumberOfFilter = () => {
    const selectedLabelsFilter =
      roadmapFilter.labels.length > 0 && !isAllProductOnlySelected ? 1 : 0;
    const resolutionCount = roadmapFilter.resolution !== 'all' ? 1 : 0;
    const roadmapFilterCount =
      roadmapFilter.ETAorVoted !== RoadmapETAorVotedFilter.ALL ? 1 : 0;
    return isMobileView
      ? selectedLabelsFilter + resolutionCount + roadmapFilterCount
      : roadmapFilterCount;
  };

  const numberOfFilter = getNumberOfFilter();

  return (
    <Box
      dataHook={DATA_HOOKS.MOBILE_SORT_AND_FILTER_MENU}
      className={css.mobileSortAndFilterMenu}
    >
      <WalkthroughPopover
        walkthroughLevels={
          isMobileView
            ? WalkthroughLevels.SELECT_PRODUCT
            : WalkthroughLevels.FILTERS
        }
        opened={
          isMobileView
            ? walkthroughStep === WalkthroughLevels.SELECT_PRODUCT
            : walkthroughStep === WalkthroughLevels.FILTERS
        }
        placement="bottom"
        onClose={() => onChangeWalkthroughStep(null)}
        onChangeLevel={(walkthroughLevels: WalkthroughLevels) =>
          onChangeWalkthroughStep(walkthroughLevels)
        }
      >
        <span className={`${css.mobileSortIconWrapper}`}>
          <IconButton
            dataHook={DATA_HOOKS.MOBILE_SORT_ICON_BUTTON}
            onClick={openModal}
            className={`${css.modalIconButton} ${
              isMobileView ? css.mobile : ''
            }`}
            priority="secondary"
            size="tiny"
          >
            <ChangeOrderSmall height={'11px'} width={'11px'} />
          </IconButton>

          <div
            className={`${css.counterBadgeWrapper} ${
              isMobileView ? css.mobile : ''
            }`}
          >
            {numberOfFilter > 0 && (
              <CounterBadge skin="neutralStandard" size="small">
                {numberOfFilter}
              </CounterBadge>
            )}
          </div>
        </span>
        {!isMobileView && (
          <IconButton
            dataHook={DATA_HOOKS.MOBILE_SORT_ICON_BUTTON}
            onClick={openModal}
            className={css.smallScreenModalIconButton}
            priority="secondary"
            size="medium"
          >
            <ChangeOrderSmall height={'11px'} width={'11px'} />
          </IconButton>
        )}
      </WalkthroughPopover>
      <Modal
        dataHook={DATA_HOOKS.FILTER_AND_SORT_MODAL}
        isOpen={isModalOpen}
        onRequestClose={closeModal}
        screen="mobile"
        zIndex={MOVE_TO_TOP_Z_INDEX}
      >
        {modal}
      </Modal>
    </Box>
  );
};

export default RoadmapMobileSortAndFilterMenu;
