import { Box } from '@wix/design-system';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import {
  ArticleSearchResultItem,
  SearchResultItem,
  PageType,
} from '../../../types';
import { ResultsFooter } from './ResultsFooter';
import { DeprecatedInternalSearchBarResults } from './DeprecatedInternalSearchBarResults';
import css from './index.module.scss';

export type SearchBarResultsProps = {
  articles: ArticleSearchResultItem[];
  inputText: string;
  focusedArticleIndex: number;
  onSearchResultClick: (article: ArticleSearchResultItem, index: number) => Promise<void>;
  onClickAllResults: () => Promise<void>;
  setFocusedArticleIndex: (index: number) => void;
  setFocusedResult: (result: SearchResultItem) => void;
  resultsListRef: React.RefObject<HTMLDivElement>;
  pageType: PageType;
};

export const SearchBarResults: FunctionComponent<SearchBarResultsProps> = ({
  articles,
  inputText,
  focusedArticleIndex,
  onSearchResultClick,
  onClickAllResults,
  setFocusedArticleIndex,
  resultsListRef,
}) => {
  const areResultsPresent = articles && articles.length > 0;

  return (
    <Box
      ref={resultsListRef}
      display={areResultsPresent ? 'block' : 'none'}
      className={css.searchBarResults}
      dataHook={DATA_HOOKS.SEARCH_BAR_RESULTS}
      direction="vertical"
    >
    <DeprecatedInternalSearchBarResults
      articles={articles}
      inputText={inputText}
      focusedArticleIndex={focusedArticleIndex as number}
      onSearchResultClick={onSearchResultClick}
      setFocusedArticleIndex={setFocusedArticleIndex}
    />
      <ResultsFooter
        inputText={inputText}
        resultsCount={articles?.length ?? 0}
        onClickAllResults={onClickAllResults}
        focusedArticleIndex={focusedArticleIndex}
      />
    </Box>
  );
};
