import { helpCenterBannerView, pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useBI } from '../../../hooks/useBI';
import { useRouter } from 'next/router';
import { BI } from '../../../constants';

type EnrollmentFeatureBannerShowData = {
  content: string;
};

export const useRoadmapHeaderBi = () => {
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();

  const commonBiData = {
    source_name: BI.SOURCE_NAMES.ROADMAP,
    kb_lang: locale
  };

  const enrollmentFeatureBannerShow = (data: EnrollmentFeatureBannerShowData) => {
    void sendBIEvent(
      helpCenterBannerView({
        banner_language: locale,
        banner_type: BI.BANNER_TYPE.NEW_FEATURE_ANNOUNCEMENT,
        source_name: BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        banner_name: BI.BANNER_NAME.NEW_FEATURE_FEATURE_REQUEST_ENROLLMENT,
        banner_text: data.content,
      })
    );
  };

  const onRequestFeatureClick = () => {
    void sendBIEvent(
      pageClick({
        ...commonBiData,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.REQUEST_A_FEATURE,
        clicked_text: BI.CLICKED_ITEM_TYPES.REQUEST_A_FEATURE,
      })
    );
  };

  const onRequestFeatureClickFromMobile = () => {
    void sendBIEvent(
      pageClick({
        ...commonBiData,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.REQUEST_A_FEATURE_DIRECT_TO_DESKTOP,
        clicked_text: BI.CLICKED_ITEM_TYPES.REQUEST_A_FEATURE,
      })
    );
  };

  return { onRequestFeatureClick, onRequestFeatureClickFromMobile, enrollmentFeatureBannerShow };
};
