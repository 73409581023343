import * as DOMPurify from 'isomorphic-dompurify';
import { MAX_PERSONALIZED_NAME_LENGTH } from '../constants';

export const stripHTML = (html: string): string => html.replace(/<[^>]+>/g, '');

export const sanitizeHtml = (html: string) => {
  const DEFAULT_ALLOWED_TAGS_FROM_SANITIZE_HTML = [
    'address',
    'article',
    'aside',
    'footer',
    'header',
    'h1',
    'h2',
    'h3',
    'h4',
    'h5',
    'h6',
    'hgroup',
    'main',
    'nav',
    'section',
    'blockquote',
    'dd',
    'div',
    'dl',
    'dt',
    'figcaption',
    'figure',
    'hr',
    'li',
    'main',
    'ol',
    'p',
    'pre',
    'ul',
    'a',
    'abbr',
    'b',
    'bdi',
    'bdo',
    'br',
    'cite',
    'code',
    'data',
    'dfn',
    'em',
    'i',
    'kbd',
    'mark',
    'q',
    'rb',
    'rp',
    'rt',
    'rtc',
    'ruby',
    's',
    'samp',
    'small',
    'span',
    'strong',
    'sub',
    'sup',
    'time',
    'u',
    'var',
    'wbr',
    'caption',
    'col',
    'colgroup',
    'table',
    'tbody',
    'td',
    'tfoot',
    'th',
    'thead',
    'tr',
    'img',
    'bold',
    'span',
  ];

  const DEFAULT_ALLOWED_TAGS = [
    ...DEFAULT_ALLOWED_TAGS_FROM_SANITIZE_HTML,
    'img',
    'bold',
    'span',
  ];

  const DEFAULT_ALLOWED_ATTRS = [
    'class',
    'src',
    'bold',
    'img',
    'span',
    'href',
    'target',
    'width',
    'height',
  ];

  DOMPurify.addHook('afterSanitizeAttributes', (node) => {
    if (node.tagName === 'IMG') {
      const rawSrc = node.getAttribute('src') || '';

      if (rawSrc) {
        const processedSrc = rawSrc.replace('http://', 'https://');
        node.setAttribute('src', processedSrc);
      }
    } else {
      node.removeAttribute('width');
      node.removeAttribute('height');
    }
  });

  const sanitizedHtml = DOMPurify.sanitize(html, {
    ALLOWED_TAGS: DEFAULT_ALLOWED_TAGS,
    ALLOWED_ATTR: DEFAULT_ALLOWED_ATTRS,
  });

  DOMPurify.removeAllHooks();

  return sanitizedHtml;
};

const LINK_REGEX =
  /(https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|www\.[a-zA-Z0-9][a-zA-Z0-9-]+[a-zA-Z0-9]\.[^\s]{2,}|https?:\/\/(?:www\.|(?!www))[a-zA-Z0-9]+\.[^\s]{2,}|www\.[a-zA-Z0-9]+\.[^\s]{2,})/g;
const linkTemplate = (link: string) =>
  `<a target="_blank" href="${link}">${link}</a>`;
export const contentToHtml = (content: string): string =>
  content
    .replace(LINK_REGEX, (link) => {
      let l = link;
      if (link.startsWith('www')) {
        l = `https://${link}`;
      }
      return linkTemplate(l);
    })
    .split('\n')
    .map((sentence) => `<p>${sentence}</p>`)
    .join('\n');

export const validateGUID = (uuid: string): boolean =>
  new RegExp(
    '^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$',
    'i'
  ).test(uuid);

export const handleLongFirstName = (firstName?: string) => {
  if (firstName && firstName.length > MAX_PERSONALIZED_NAME_LENGTH) {
    return firstName?.slice(0, MAX_PERSONALIZED_NAME_LENGTH) + '...';
  }
  return firstName;
};

export const countWords = (stringToBreak: string) => {
  // Match words, including those separated by multiple spaces or new lines
  const words = stringToBreak.match(/[^\s]+/g);
  return words ? words.length : 0;

};

export const removeUrlsWithBrackets = (input: string): string => {
  const regex = /\([^\s]*https?:\/\/[^\s]*\)/g;
  return input ? input.replace(regex, '') : '';
};
