import moment from 'moment';
import { getLocalStorage, setLocalStorage } from './webStorageWrapper';

export const setNotification = (notificationId: string) => {
  setLocalStorage(
    'notificationList',
    JSON.stringify({
      ...getNotificationList(),
      [notificationId]: new Date(),
    })
  );
};

export const getNotificationList = () => {
  const list = getLocalStorage('notificationList');
  return JSON.parse(list || '{}');
};

export const isNotificationShowed = (notificationId: string): boolean => {
  const notificationsList = getNotificationList();
  const lastDate = notificationsList[notificationId];

  if (!lastDate) {
    return false;
  }
  const hoursDiff = moment().diff(lastDate, 'hours');

  return hoursDiff < 24;
};
