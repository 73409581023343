import { FunctionComponent, useEffect, useRef } from 'react';
import { useInterval } from '../../../hooks/useInterval';

export enum ActivityType {
  SCROLL = 'scroll',
  MOUSEMOVE = 'mousemove',
  CLICK = 'click',
}

export type UserEngagementPingerProps = {
  onPing: (loggedActivities: ActivityType[]) => void;
  pingInterval: number;
};

export const UserEngagementPinger: FunctionComponent<
  UserEngagementPingerProps
> = ({ onPing, pingInterval }: UserEngagementPingerProps) => {
  const initialActivities = new Set<ActivityType>();
  const loggedActivitiesRef = useRef(initialActivities);

  useInterval(() => {
    if (!document.hidden) {
      onPing(Array.from(loggedActivitiesRef.current));
    }
    loggedActivitiesRef.current.clear();
  }, pingInterval);

  const updateActivities = (activity: ActivityType) =>
    loggedActivitiesRef.current.add(activity);
  const handleClick = () => updateActivities(ActivityType.CLICK);
  const handleScroll = () => updateActivities(ActivityType.SCROLL);
  const handleMousemove = () => updateActivities(ActivityType.MOUSEMOVE);

  useEffect(() => {
    window.addEventListener(ActivityType.CLICK, handleClick);
    window.addEventListener(ActivityType.SCROLL, handleScroll);
    window.addEventListener(ActivityType.MOUSEMOVE, handleMousemove);
    return () => {
      window.removeEventListener(ActivityType.CLICK, handleClick);
      window.removeEventListener(ActivityType.SCROLL, handleScroll);
      window.removeEventListener(ActivityType.MOUSEMOVE, handleMousemove);
    };
  }, []);

  return null;
};
