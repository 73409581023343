import React, { FunctionComponent, useEffect } from 'react';

export const LoaderIcon: FunctionComponent = () => {
  const ref = React.useRef<HTMLVideoElement>(null);

  useEffect(() => {
    const devicePixelRatio =
      window.devicePixelRatio ||
      (window.screen as any).deviceXDPI / (window.screen as any).logicalXDPI;
    const retinaSuffix = devicePixelRatio >= 2 ? '-@2x' : '';

    const random1To5 = Math.floor(Math.random() * 5) + 1;

    if (ref?.current) {
      ref.current.width = 200;
      ref.current.height = 200;
      ref.current.src =
        'https:\u002F\u002Fstatic.parastorage.com\u002Fservices\u002Fsanta-resources\u002Fresources\u002Feditor\u002Fpreloader\u002FvideoPreloader' +
        '-' +
        random1To5 +
        retinaSuffix +
        '.v2.mp4';
      ref.current.load();
    }
  }, []);

  return (
    <div id="video-preloader">
      <video
        ref={ref}
        width="200"
        height="200"
        poster="data:image/gif;base64,R0lGODlhAQABAIAAAP///wAAACwAAAAAAQABAAACAkQBADs="
        src="https://static.parastorage.com/services/santa-resources/resources/editor/preloader/videoPreloader-1-@2x.v2.mp4"
        loop
        autoPlay
        muted
      ></video>
    </div>
  );
};
