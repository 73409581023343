import { ArticleResolution, ArticleType, Category } from '@wix/answers-api';
import { Badge, Box, Tag } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../../dataHooks';
import { InnerLink } from '../../../InnerLink';
import { RoadmapResolutionBadge } from '../../../RoadmapResolutionBadge';
import { LOCALES } from '../../../../constants';
import { useRouter } from 'next/router';
import {
  resolutionTransformLocalize,
  shouldLocalize,
} from '../../../../utils/roadmapLocalizationKit';
import css from './index.module.scss';
import { ArticleSearchResultDocumentType } from '../../../../types';
import { ExternalLink as ExternalLinkIcon } from 'wix-ui-icons-common';

export type SearchResultItemBadgesProps = {
  type: ArticleType;
  docType?: ArticleSearchResultDocumentType;
  resolution: ArticleResolution | null;
  resolutionEta?: number | null;
  rootTopic?: Category;
  onArticleTopicBadgeClick: (rootTopic: Category) => void;
  onArticleResolutionBadgeClick: (clickedText: string, url: string) => void;
  onArticleTypeTagClick: (clickedText: string, url: string) => void;
};

export const ROADMAP_RESOLUTIONS = [
  ArticleResolution.COLLECTING_VOTES,
  ArticleResolution.PLANNED,
  ArticleResolution.IN_DEVELOPMENT,
  ArticleResolution.COMING_SOON,
  ArticleResolution.RELEASED,
];

export const SearchResultItemBadges: FunctionComponent<
  SearchResultItemBadgesProps
> = ({
  rootTopic,
  type,
  resolution,
  resolutionEta,
  onArticleTopicBadgeClick,
  onArticleResolutionBadgeClick,
  docType,
}) => {
  const { t } = useTranslation();
  const { locale } = useRouter();

  const isWixDocument =
    docType === ArticleSearchResultDocumentType.WIX_DOCS_ARTICLE;

  const isRoadMapResolution =
    resolution && ROADMAP_RESOLUTIONS.includes(resolution);

  const getArticleTypeTitle = (articleType: ArticleType): string => {
    switch (articleType) {
      case ArticleType.FEATURE_REQUEST:
        return t('feature.request.title');
      case ArticleType.KNOWN_ISSUE:
        return t('known.issue.title');
      case ArticleType.ARTICLE:
      default:
        if (isWixDocument) {
          return t('search.results.document.title');
        }
        return t('article.title');
    }
  };

  const transformedResolution = resolutionTransformLocalize(
    resolution as ArticleResolution,
    locale
  );

  const renderRoadmapResolutionBadge = () => {
    const badge = (
      <RoadmapResolutionBadge
        resolution={transformedResolution}
        size="tiny"
        showTooltip
        resolutionEta={shouldLocalize(locale) ? undefined : resolutionEta}
        onClickStatus={
          shouldLocalize(locale)
            ? undefined
            : (res, clickedText) =>
                onArticleResolutionBadgeClick(
                  clickedText,
                  `${location.origin}/${LOCALES.EN}/roadmap?resolution=${res}`
                )
        }
      />
    );

    if (shouldLocalize(locale)) {
      return badge;
    } else {
      return (
        <InnerLink
          locale={LOCALES.EN}
          href={`/roadmap?resolution=${resolution}`}
          className={css.topicBadgeLink}
        >
          {badge}
        </InnerLink>
      );
    }
  };

  return (
    <Box
      className={css.searchResultItemBadges}
      verticalAlign="middle"
      dataHook={DATA_HOOKS.SEARCH_RESULT_ITEM_BADGES}
    >
      {rootTopic ? (
        <InnerLink
          href={rootTopic.uri}
          onClick={() => onArticleTopicBadgeClick(rootTopic)}
          className={css.topicBadgeLink}
        >
          <div tabIndex={0}>
            <Badge
              size="tiny"
              skin="neutralLight"
              dataHook={DATA_HOOKS.SEARCH_RESULT_ITEM_TOPIC_BADGE}
              className={css.topicBadge}
            >
              {rootTopic.name}
            </Badge>
          </div>
        </InnerLink>
      ) : null}
      {isRoadMapResolution ? (
        renderRoadmapResolutionBadge()
      ) : (
        <div tabIndex={0}>
          <Tag
            id={`${type}`}
            size="tiny"
            className={css.typeTag}
            removable={false}
            theme="lightOutlined"
            onClick={
              isWixDocument
                ? () => window.open('https://dev.wix.com/docs', '_blank')
                : undefined
            }
            dataHook={DATA_HOOKS.SEARCH_RESULT_ITEM_TYPE_BADGE}
          >
            <Box>
              {getArticleTypeTitle(type)}
              {isWixDocument && <ExternalLinkIcon width={18} height={18} />}
            </Box>
          </Tag>
        </div>
      )}
      <div className={css.scrollableIndicator} />
    </Box>
  );
};
