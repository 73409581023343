/* eslint-disable max-lines */
import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import { Box, TagList } from '@wix/design-system';
import css from './index.module.scss';
import { ArticleSortType } from '@wix/answers-api';
import { ALL_PRODUCTS_LABEL_ID, BI } from '../../../../constants';
import { Context } from '../../../../context';
import { useBI } from '../../../../hooks/useBI';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { isMobile, isTablet } from '../../../../utils/userAgent';

export type RoadmapTagSelectedProductsProps = {
  selectedLabels: {
    id: string;
    name: string;
  }[];
  onTagRemove: (id: string) => void;
  onClear: () => void;
};
export const ALL_SORT_OPTION = [
  ArticleSortType.LAST_PUBLISH_DATE_DESC,
  ArticleSortType.VOTES_DESC,
];

export const RoadmapTagSelectedProducts: FunctionComponent<
  RoadmapTagSelectedProductsProps
> = ({ selectedLabels, onTagRemove, onClear }) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { sendBIEvent } = useBI();
  const isTabletView = isTablet(context);
  const isAllProductOnlySelected =
    selectedLabels.length === 1 &&
    selectedLabels[0].id === ALL_PRODUCTS_LABEL_ID;

  const pageClickEvent = async (clickedItemText: string) => {
    await sendBIEvent(
      pageClick({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.PRODUCT_FILTER,
        clicked_text: clickedItemText,
      })
    );
  };
  const onClickClearButton = async () => {
    onClear();
    await pageClickEvent(t('roadmap.selected-products.clear-all'));
  };

  const tags = selectedLabels
    .filter((selectedOption) => selectedOption.id !== ALL_PRODUCTS_LABEL_ID)
    .map((product) => ({
      id: product.id,
      children: product.name,
    }));
  const toggleMoreButton = (
    amountOfHiddenTags: number,
    isExpanded: boolean
  ) => ({
    label: isExpanded
      ? t('roadmap.selected-products.show-less')
      : t('roadmap.selected-products.show-more', {
          number: amountOfHiddenTags,
        }),
    tooltipContent: !isExpanded && t('roadmap.selected-products.tooltip'),
  });
  const showClearButton = selectedLabels.length && !isAllProductOnlySelected;

  return (
    <Box
      paddingTop={isMobileView ? 'SP2' : 'SP4'}
      width={'100%'}
      className={css.selectedProduct}
    >
      <TagList
        tags={tags}
        maxVisibleTags={isMobileView ? 5 : isTabletView ? 13 : 17}
        actionButton={
          showClearButton
            ? {
                label: t('roadmap.selected-products.clear-all'),
                onClick: onClickClearButton,
              }
            : undefined
        }
        toggleMoreButton={toggleMoreButton}
        onTagRemove={(id: string) => onTagRemove(id)}
      />
    </Box>
  );
};
