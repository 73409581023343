import React, {
  FunctionComponent,
  useCallback,
  useContext,
  useEffect,
} from 'react';
import { Box, Text, TextButton } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { ArticleResolution } from '@wix/answers-api';
import { DATA_HOOKS } from '../../../dataHooks';
import { ArticleSearchResultItem, RoadmapETAorVotedFilter } from '../../../types';
import css from './index.module.scss';
import { COLORS } from '../../../utils/theme';
import { Context } from '../../../context';
import { isMobile } from '../../../utils/userAgent';
import { loginWithAnswersSSO } from '../../../utils/login';
import { RenderHTML } from '../../RenderHTML';
import { InnerLink } from '../../InnerLink';
import { RoadmapArticleListItemFooter } from './RoadmapArticleListItemFooter';
import RoadmapVote from '../RoadmapVote';

export type RoadmapArticleListItemProps = {
  article: ArticleSearchResultItem;
  articleLabels: { id: string; name: string }[];
  onSelectLabel: (label: { id: string; name: string }) => void;
  onSelectStatus: (resolution: ArticleResolution) => void;
  followArticle: (articleId: string) => void;
  unFollowArticle: (articleId: string) => void;
  onSelectArticle: (article: ArticleSearchResultItem) => void;
  locale: string;
  filter: RoadmapETAorVotedFilter;
  userFollowedArticle: string[];
  articlePopulationStatus: Record<string, boolean>;
  togglePopulationStatus: (featureId: string) => void;
};
export const LABELS_LENGTH = 3;

export const MAX_PREVIEW_SIZE = 300;

const RoadmapArticleListItem: FunctionComponent<
  RoadmapArticleListItemProps
> = ({
  article,
  followArticle,
  unFollowArticle,
  onSelectArticle,
  onSelectStatus,
  articleLabels,
  onSelectLabel,
  locale,
  filter,
  userFollowedArticle,
  articlePopulationStatus,
  togglePopulationStatus,
}: RoadmapArticleListItemProps) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { isLoggedInUser } = context;
  const isMobileView = isMobile(context);

  const [showVoteContent, setShowVoteContent] = React.useState(false);
  const [showAllLabels, setShowAllLabels] = React.useState(false);
  const [isFollowing, setIsFollowing] = React.useState(false);

  const isPreviousVote = useCallback((): boolean => {
    const isFollowingArticle = userFollowedArticle.includes(article.id);
    return isFollowingArticle;
  }, [article, userFollowedArticle]);

  useEffect(() => {
    setIsFollowing(isPreviousVote());
  }, [isPreviousVote]);

  const articleContent = article.description ? (
    <Text
      weight="thin"
      maxLines={2}
      dataHook={DATA_HOOKS.ARTICLE_ITEM_CONTENT}
      className={`${css.articleContent}`}
      size="small"
    >
      <RenderHTML html={article.description} />
    </Text>
  ) : null;
  const renderLabel = (
    label: { id: string; name: string },
    isLastOne: boolean
  ) => {
    const onClick = () => onSelectLabel(label);

    return (
      <TextButton
        dataHook={DATA_HOOKS.ARTICLE_ITEM_LABEL}
        onClick={onClick}
        className={css.LabelNameWrapper}
        color={COLORS.D40}
        underline="onHover"
        size="small"
        key={`article-${label.id}`}
      >
        {label ? label.name : ''}
        {!isLastOne && ' · '}
      </TextButton>
    );
  };
  const clickFollowArticle = async () => {
    if (isLoggedInUser) {
      followArticle(article.id);
      setIsFollowing(true);
      setShowVoteContent(true);
    } else {
      loginWithAnswersSSO(location.href, locale);
    }
  };
  const clickUnFollowArticle = async () => {
    unFollowArticle(article.id);
    setIsFollowing(false);
    setShowVoteContent(false);
  };

  const renderLabels = (labels: { id: string; name: string }[]) => {
    const hasMore = labels.length > LABELS_LENGTH;
    return (
      <Box className={`${css.labelsWrapper}`}>
        {labels.map((label: { id: string; name: string }, idx: number) => {
          const showLabel = idx <= LABELS_LENGTH - 1 || showAllLabels;
          return showLabel && renderLabel(label, idx === labels.length - 1);
        })}
        {!showAllLabels && hasMore && (
          <TextButton onClick={() => setShowAllLabels(true)} size="small">
            {t('roadmap.article-item.show-more', {
              number: articleLabels.length - LABELS_LENGTH,
            })}
          </TextButton>
        )}
      </Box>
    );
  };

  return (
    <Box key={article.id} className={`${css.articleWrapper}`}>
      <div className={`${css.articleLeftPartWrapper}`}>
        {article.labels &&
          articleLabels.length > 0 &&
          renderLabels(articleLabels)}
        <InnerLink
          onClick={() => onSelectArticle(article)}
          className={`${css.articleTextPart}`}
          href={article.uri}
        >
          <Box direction="vertical">
            <Text
              dataHook={DATA_HOOKS.ARTICLE_ITEM_TITLE}
              size="medium"
              weight="bold"
              type="Headings"
              className={css.articleTitle}
            >
              {article.title}
            </Text>
            <div className={`${css.articleContentWrapper}`}>
              {articleContent}
            </div>
          </Box>
        </InnerLink>
        <RoadmapArticleListItemFooter
          article={article}
          followArticle={clickFollowArticle}
          unFollowArticle={clickUnFollowArticle}
          isFollowing={isFollowing}
          onClickStatus={onSelectStatus}
          togglePopulationStatus={togglePopulationStatus}
          articlePopulationStatus={articlePopulationStatus}
        />
        {isMobileView && showVoteContent && (
          <Box width={'100%'} align="right">
            <Text className={css.voteContent} textAlign="center" size="tiny">
              {t('roadmap.article-item.vote-content')}
            </Text>
          </Box>
        )}
      </div>
      {!isMobileView && (
        <Box
          align="left"
          minWidth={
            article.resolution === ArticleResolution.RELEASED
              ? '138px'
              : undefined
          }
        >
          {article.resolution !== ArticleResolution.RELEASED && (
            <RoadmapVote
              article={article}
              followArticle={followArticle}
              unFollowArticle={unFollowArticle}
              locale={locale}
              filter={filter}
              isFollowingArticle={userFollowedArticle.includes(article.id)}
              articlePopulationStatus={articlePopulationStatus}
              togglePopulationStatus={togglePopulationStatus}
            />
          )}
        </Box>
      )}
    </Box>
  );
};

export default RoadmapArticleListItem;
