import React, { FunctionComponent, useContext } from 'react';
import { useTranslation } from '@wix/wix-i18n-config';
import {
  Box,
  Text,
  FloatingHelperPlacement,
  Popover,
  CloseButton,
  IconButton,
} from '@wix/design-system';
import css from './index.module.scss';
import { TsUtils } from '@wix/answers-api';
import { WalkthroughLevels } from '..';
import { DATA_HOOKS } from '../../../dataHooks';
import { Context } from '../../../context';
import { isMobile } from '../../../utils/userAgent';
import { COLORS } from '../../../utils/theme';
import { setLocalStorage } from '../../../utils/webStorageWrapper';
import { WalkthroughIndicationIcon } from '../../../icons/WalkthroughIndicatorIcon';
import { WalkthroughIndicationFilledIcon } from '../../../icons/WalkthroughIndicatorFIlledIcon';
import ChevronRightLargeSmall from 'wix-ui-icons-common/ChevronRightLargeSmall';
import ChevronLeftLargeSmall from 'wix-ui-icons-common/ChevronLeftLargeSmall';
import { useBI } from '../../../hooks/useBI';
import { helpCenterModals } from '@wix/bi-logger-new-help-center/v2';
import { BI } from '../../../constants';
import { useRouter } from 'next/router';

export type WalkthroughPopoverProps = {
  walkthroughLevels: WalkthroughLevels;
  onClose: () => void;
  onChangeLevel: (walkthroughLevels: WalkthroughLevels) => void;
  children: React.ReactNode;
  opened: boolean;
  placement: FloatingHelperPlacement;
};

export const WalkthroughPopover: FunctionComponent<WalkthroughPopoverProps> = ({
  walkthroughLevels,
  onClose,
  onChangeLevel,
  children,
  opened,
  placement,
}) => {
  const { t } = useTranslation();
  const context = useContext(Context);
  const { isWixStudioUser } = useContext(Context);
  const { sendBIEvent } = useBI();
  const { locale } = useRouter();
  const isMobileView = isMobile(context);
  const allWalkthroughLevels = TsUtils.enumToArray(
    WalkthroughLevels
  ) as WalkthroughLevels[];
  const allWalkthroughLevelsFiltered =
    isMobileView && !isWixStudioUser
      ? allWalkthroughLevels.filter(
          (level) => level !== WalkthroughLevels.FILTERS
        )
      : allWalkthroughLevels;

  const onChange = (isBack?: boolean) => {
    switch (walkthroughLevels) {
      case WalkthroughLevels.FILTERS:
        onChangeLevel(WalkthroughLevels.SELECT_PRODUCT);
        break;
      case WalkthroughLevels.SEARCH:
        onChangeLevel(WalkthroughLevels.SELECT_PRODUCT);
        break;
      case WalkthroughLevels.SELECT_PRODUCT:
        if (isBack) {
          onChangeLevel(WalkthroughLevels.SEARCH);
        } else {
          onChangeLevel(WalkthroughLevels.FILTERS);
        }
        break;
      default:
        onChangeLevel(WalkthroughLevels.SEARCH);
        break;
    }
  };

  const sendHelpCenterModalsBIevent = async (clickedButtonName: string) => {
    await sendBIEvent(
      helpCenterModals({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        modal_name: BI.MODAL_NAME.ROADMAP_WALKTHROUGH,
        screen_name: `step_${walkthroughLevels}`,
        kb_lang: locale as string,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: clickedButtonName,
      })
    );
  };
  const onCloseWalkthrough = async () => {
    setLocalStorage('showRoadmapV2Tour', 'false');
    await sendHelpCenterModalsBIevent(BI.CLICKED_ITEM_TYPES.CLOSE);
    onClose();
  };
  const changeTextForMobile =
    isMobileView && walkthroughLevels !== WalkthroughLevels.SEARCH;

  const onClickNext = async () => {
    onChange();
    await sendHelpCenterModalsBIevent(BI.CLICKED_ITEM_TYPES.NEXT_BUTTON);
  };
  const onClickBack = async () => {
    onChange(true);
    await sendHelpCenterModalsBIevent(BI.CLICKED_ITEM_TYPES.BACK);
  };

  const renderIndicator = (idx: number) =>
    idx === walkthroughLevels ? (
      <WalkthroughIndicationFilledIcon key={idx} />
    ) : (
      <WalkthroughIndicationIcon key={idx} />
    );

  const body = (
    <Box
      className={css.walkthroughContent}
      width={'100%'}
      height={'100%'}
      direction="vertical"
      verticalAlign="middle"
      align="center"
      key={walkthroughLevels}
    >
      <Text
        className={css.walkthroughTitle}
        textAlign="center"
        size="small"
        weight="bold"
      >
        {changeTextForMobile
          ? t(`roadmap.walkthrough.mobile.title.${walkthroughLevels}`)
          : t(`roadmap.walkthrough.title.${walkthroughLevels}`)}
      </Text>
      <Text
        className={css.walkthroughSubtitle}
        textAlign="center"
        size="small"
        weight="thin"
      >
        {changeTextForMobile
          ? t(`roadmap.walkthrough.mobile.subtitle.${walkthroughLevels}`)
          : t(`roadmap.walkthrough.subtitle.${walkthroughLevels}`)}
      </Text>
    </Box>
  );
  const footer = (
    <Box
      className={css.walkthroughFooter}
      verticalAlign="middle"
      align="space-between"
      padding={'0 SP3 SP3 SP3'}
    >
      <IconButton
        className={`${css.backButton} ${
          walkthroughLevels === WalkthroughLevels.SEARCH ? css.hidden : ''
        }`}
        onClick={onClickBack}
        size="small"
        skin="standard"
        priority="secondary"
      >
        <ChevronLeftLargeSmall />
      </IconButton>
      <Box gap={'SP1'}>
        {allWalkthroughLevelsFiltered.map((level) => renderIndicator(level))}
      </Box>
      <IconButton
        className={`${css.nextButton} ${
          walkthroughLevels === WalkthroughLevels.FILTERS ? css.hidden : ''
        }`}
        onClick={onClickNext}
        size="small"
        skin="standard"
        priority="secondary"
      >
        <ChevronRightLargeSmall />
      </IconButton>
    </Box>
  );

  return (
    <Popover
      className={css.walkthroughMobilePopover}
      showArrow
      dataHook={
        walkthroughLevels === WalkthroughLevels.SEARCH
          ? DATA_HOOKS.ROADMAP_WALKTHROUGH_SEARCH
          : DATA_HOOKS.ROADMAP_WALKTHROUGH
      }
      shown={opened}
      appendTo={isMobileView ? 'viewport' : 'window'}
      placement={placement}
      moveBy={
        walkthroughLevels !== WalkthroughLevels.SEARCH ? { y: 3 } : undefined
      }
    >
      <Popover.Element>{children}</Popover.Element>
      <Popover.Content>
        <Box width={'300px'} direction="vertical">
          <Box width={'100%'} align="right">
            <CloseButton
              onClick={onCloseWalkthrough}
              className={css.walkthroughCloseButton}
              size="medium"
              color={COLORS.D10}
            />
          </Box>
          <Box padding={'0 18px 12px 18px'}>{body}</Box>
          {footer}
        </Box>
      </Popover.Content>
    </Popover>
  );
};
