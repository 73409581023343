import React, { useEffect, useState, useContext, createContext } from 'react';
import { useRouter } from 'next/router';

type HistoryDataContext = {
  history: string[];
  prePageUrl?: string;
};

export const HistoryContext = createContext<HistoryDataContext>({
  history: [],
});
export const useHistoryContext = () =>
  useContext<HistoryDataContext>(HistoryContext);

export const usePreviousRoute = () => {
  const router = useRouter();
  const isSSR = typeof location === 'undefined';
  const baseUrl = isSSR ? '' : location.origin;
  const [history, setHistory] = useState<string[]>(getFirstHistoryArray(isSSR));

  useEffect(() => {
    const handleRouteChange = (url: string) => {
      setHistory([...history, `${baseUrl}${url}`]);
    };

    router.events.on('routeChangeStart', handleRouteChange);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
    };
  }, [router, baseUrl, history]);
  const preUrl = history[history.length - 2];
  return { history, prePageUrl: preUrl === '' ? undefined : preUrl };
};

const getFirstHistoryArray = (isSSR: boolean): string[] => {
  if (isSSR) {
    return [];
  }
  const history = [];
  if (document.referrer !== '') {
    history.push(document.referrer);
  } else {
    history.push('');
  }
  history.push(location.href);

  return history;
};

export const HistoryProvider = ({
  children,
}: {
  children: React.ReactChild;
}) => {
  const historyProps = usePreviousRoute();

  return (
    <HistoryContext.Provider
      value={{
        ...historyProps,
      }}
    >
      {children}
    </HistoryContext.Provider>
  );
};

export default HistoryProvider;
