import React, { FunctionComponent, useEffect, useState } from 'react';
import { pageEngagement } from '@wix/bi-logger-new-help-center/v2';
import { useRouter } from 'next/router';
import { BI } from '../../constants';
import { useBI } from '../../hooks/useBI';
import { useMaxScrollPercentage } from '../../hooks/useScrollPercentage';
import { PageData } from '../PageTemplate';
import { PageType, PAGES } from '../../types';
import { UserEngagementPinger } from './UserEngagementPinger';
import { tabToName, sortTypeToName, FilterToName } from '../../utils/roadmap';

type UserEngagementProps = {
  pageType: PageType;
  pageData: PageData;
  itemType: string;
  itemId?: string;
};

export const UserEngagement: FunctionComponent<UserEngagementProps> = ({
  pageData,
  pageType,
  itemType,
  itemId,
}) => {
  const { sendBIEvent } = useBI();
  const [isLoadingPage, setIsLoadingPage] = useState(false);
  const [maxScrollPercentage] = useMaxScrollPercentage();
  const router = useRouter();
  const { locale } = router;

  const isPageFullyVisible = (): boolean => {
    const page = document.querySelector(BI.SELECTORS.PAGE);
    const header = document.querySelector(BI.SELECTORS.HEADER);
    return page
      ? page.scrollHeight < window.innerHeight - (header?.scrollHeight || 0)
      : false;
  };

  const onPing = async (activities: string[]): Promise<void> => {
    switch (pageType) {
      case PAGES.ARTICLE:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.ARTICLE,
            kb_lang: locale as string,
            item_id: pageData.article?.id,
            item_type: itemType,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.SEARCH_RESULTS:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.SEARCH_RESULTS,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.CATEGORY:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.CATEGORY,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            item_id: pageData?.category?.id,
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.KNOWN_ISSUES:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.KNOWN_ISSUES,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            item_id: pageData?.category?.id,
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.HOMEPAGE:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.HOME,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.TICKET:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.TICKET,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.PROFILE:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.PROFILE,
            kb_lang: locale as string,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      case PAGES.ROADMAP:
        await sendBIEvent(
          pageEngagement({
            source_name: BI.SOURCE_NAMES.ROADMAP,
            kb_lang: locale as string,
            item_id: itemId,
            item_type: itemType,
            tab: pageData.roadmapData?.resolution
              ? tabToName(pageData.roadmapData.resolution)
              : undefined,
            sort_by: pageData.roadmapData?.sortType
              ? sortTypeToName(pageData.roadmapData.sortType)
              : undefined,
            filter_by: pageData.roadmapData?.roadmapFilter?.ETAorVoted
              ? FilterToName[pageData.roadmapData.roadmapFilter.ETAorVoted]
              : undefined,
            activity_type: activities.join(','),
            max_scroll: isPageFullyVisible() ? 100 : maxScrollPercentage,
            max_scroll_at_first_position: isPageFullyVisible(),
          })
        );
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    const handleRouteChange = () => {
      setIsLoadingPage(true);
    };

    const handleRouteChangeComplete = () => {
      setIsLoadingPage(false);
    };

    router.events.on('routeChangeStart', handleRouteChange);
    router.events.on('routeChangeComplete', handleRouteChangeComplete);

    return () => {
      router.events.off('routeChangeStart', handleRouteChange);
      router.events.off('routeChangeComplete', handleRouteChangeComplete);
    };
  }, [router]);

  if (isLoadingPage) {
    return null;
  }

  return (
    <UserEngagementPinger
      key={pageType}
      onPing={onPing}
      pingInterval={BI.USER_ENGAGEMENT_PINGER_INTERVAL}
    />
  );
};
