import { useCallback, useEffect, useState } from 'react';

export const useMaxScrollPercentage = (): [number] => {
  const [maxScrollPercentage, setMaxScrollPercentage] = useState(0);

  const handleScroll = useCallback(() => {
    const footerHeight: number =
      document.querySelector('footer')?.scrollHeight || 0;
    const newScrollPercentage = Math.round(
      Math.min(
        (window.scrollY /
          (document.body.scrollHeight - window.innerHeight - footerHeight)) *
          100,
        100
      )
    );
    if (newScrollPercentage > maxScrollPercentage) {
      setMaxScrollPercentage(newScrollPercentage);
    }
  }, [maxScrollPercentage]);

  useEffect(() => {
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
    };
  }, [handleScroll]);

  return [maxScrollPercentage];
};
