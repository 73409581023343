import React, { FunctionComponent } from 'react';
import Head from 'next/head';
import { Category, Translation } from '@wix/answers-api';
import { BasicSeoElements } from '../BasicSEO';
import { LOCALES, PRODUCTION_DOMAIN, SEO } from '../../../constants';
import { PageSeo } from '../../../types';

type CategoryPageProps = {
  category?: Category;
  translations?: Translation[];
  pageSeo: PageSeo;
};

export const CategoryPageSEO: FunctionComponent<CategoryPageProps> = ({
  category,
  pageSeo,
  translations,
}) => {
  const fillPlaceholders = (text: string): string =>
    text
      .replace(SEO.PLACEHOLDERS.CATEGORY_TITLE, category?.name || '')
      .replace(SEO.PLACEHOLDERS.COMPANY_NAME, SEO.COMPANY_NAME);

  const description = pageSeo ? fillPlaceholders(pageSeo.description) : '';
  const title = pageSeo ? fillPlaceholders(pageSeo.title) : '';
  const alternateDefaultUrl = translations?.find(
    (e) => e.locale === LOCALES.EN
  )?.url;

  return (
    <>
      <BasicSeoElements
        title={title}
        description={description}
        imageUrl={pageSeo?.imageUrl}
        robots={pageSeo?.robots}
      />
      <Head>
        {translations?.map(({ url, locale: localeTarget }) => (
          <link
            rel="alternate"
            hrefLang={localeTarget}
            href={`https://${PRODUCTION_DOMAIN}/${localeTarget}${url}`}
            key={url}
          />
        ))}
        {alternateDefaultUrl && (
          <link
            rel="alternate"
            hrefLang="x-default"
            href={`https://${PRODUCTION_DOMAIN}${alternateDefaultUrl}`}
          />
        )}
      </Head>
    </>
  );
};
