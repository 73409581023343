import React, { FunctionComponent, useContext } from 'react';
import { Box, Heading } from '@wix/design-system';
import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { BI, EXPERIMENTS, LOCALES } from '../../constants';
import { DATA_HOOKS } from '../../dataHooks';
import { InnerLink } from '../InnerLink';
import { SearchBar } from '../SearchBar/Index';
import { useBI } from '../../hooks/useBI';
import { mapPageTypeToSourceName } from '../../utils/bi';
import { PageType, PAGES } from '../../types';
import { Article } from '@wix/answers-api';
import { COLORS } from '../../utils/theme';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { Context } from '../../context';
import { SearchBarModal } from './SearchBarModal';
import css from './index.module.scss';

export type SubHeaderProps = {
  pageType: PageType;
  itemId?: string;
  itemType?: string;
  article?: Article;
};

export const SubHeader: FunctionComponent<SubHeaderProps> = ({
  pageType,
  itemId,
  itemType,
}: SubHeaderProps) => {
  const { locale } = useRouter();
  const { t } = useTranslation();
  const { isMobile } = useContext(Context);
  const { sendBIEvent } = useBI();
  const { experiments } = useExperiments({ readOnly: true });

  const onHomepageClick = async (): Promise<void> => {
    const sourceName = mapPageTypeToSourceName(pageType);
    await sendBIEvent(
      pageClick({
        source_name: sourceName,
        item_id: itemId,
        item_type: itemType,
        kb_lang: locale as string,
        clicked_item_type: BI.CLICKED_ITEM_TYPES.HOME_PAGE,
        clicked_text: t('subheader.wix.center'),
        clicked_url: `${location.origin}/${locale}`,
        is_page_content_click:
          sourceName === BI.SOURCE_NAMES.ARTICLE ? false : undefined,
      })
    );
  };

  const hideSubHeaderForMobileRoadmap = pageType === PAGES.ROADMAP;

  const isKnowledgeAgentSearchNonEnglishEnabled =
    locale !== LOCALES.EN &&
    experiments.enabled(EXPERIMENTS.SPECS.KNOWLEDGE_AGENT_SEARCH_NON_ENGLISH);

  const isKnowledgeAgentSearchEnabled =
    locale === LOCALES.EN || isKnowledgeAgentSearchNonEnglishEnabled;

  return !hideSubHeaderForMobileRoadmap ? (
    <Box
      direction="vertical"
      verticalAlign="middle"
      backgroundColor={COLORS.D10}
      className={css.wrapper}
      dataHook={DATA_HOOKS.KB_SUBHEADER}
    >
      <Box
        className={css.subHeaderWrapper}
        verticalAlign="middle"
        align="space-between"
      >
        {locale === LOCALES.EN && isMobile ||
        (isKnowledgeAgentSearchNonEnglishEnabled && isMobile) ? null : (
          <Heading
            size='large'
            light
            className={css.backToHomepageLink}
            as="div"
          >
            <InnerLink
              href="/"
              dataHook={DATA_HOOKS.SUBHEADER_GO_TO_HOMEPAGE}
              onClick={onHomepageClick}
            >
              {t('subheader.wix.center')}
            </InnerLink>
          </Heading>
        )}
        {isKnowledgeAgentSearchEnabled ? (
          <SearchBarModal pageType={pageType} />
        ) : (
          <SearchBar itemId={itemId} itemType={itemType} pageType={pageType} />
        )}
      </Box>
    </Box>
  ) : null;
};
