import React, { FunctionComponent } from 'react';
import Head from 'next/head';
import { BasicSeoElements } from '../BasicSEO';
import { PageSeo } from '../../../types';
import { LOCALES, PRODUCTION_DOMAIN } from '../../../constants';

type HomePageSEOProps = {
  pageSeo: PageSeo;
};

export const HomePageSEO: FunctionComponent<HomePageSEOProps> = ({
  pageSeo,
}: HomePageSEOProps) => (
    <>
      <BasicSeoElements
        title={pageSeo?.title}
        description={pageSeo?.description}
        imageUrl={pageSeo?.imageUrl}
        robots={pageSeo?.robots}
      />
      <Head>
        {Object.values(LOCALES).map((locale) => (
          <link
            rel="alternate"
            hrefLang={locale}
            href={`https://${PRODUCTION_DOMAIN}/${locale}/`}
            key={locale}
          />
        ))}
        <link
          rel="alternate"
          hrefLang="x-default"
          href={`https://${PRODUCTION_DOMAIN}/${LOCALES.EN}/`}
        />
      </Head>
    </>
  );
