export const COLORS = {
  // System Colors
  D10: '#000624',
  D20: '#333853',
  D30: '#595D70',
  D40: '#868AA5',
  D50: '#ACAFC4',
  D60: '#DFE5EB',
  D70: '#ECEFF3',
  D80: '#FFFFFF',
  B10: '#116DFF',
  B05: '#0F62E6',
  B50: '#E7F0FF',

  // Alert Colors
  G05: '#229954',
  B00: '#084EBD',
  Y10: '#FFB700',
  R10: '#D0180B',
};
