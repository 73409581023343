export const WalkthroughIndicationFilledIcon = () => (
  <svg
    width="13"
    height="4"
    viewBox="0 0 13 4"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect x="0.5" width="12" height="4" rx="2" fill="#084EBD" />
  </svg>
);
