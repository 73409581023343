
import React, {
  FC,
} from 'react';
import css from './index.module.scss';
import {
  Box,
  Button,
  CustomModalLayout,
  Modal,
  Text,
} from '@wix/design-system';
import { DATA_HOOKS } from '../../../dataHooks';
import { useTranslation } from '@wix/wix-i18n-config';
import { MOVE_TO_TOP_Z_INDEX } from '../../../constants';

export type RequestFeatureUseDesktopModalProps = {
  shown: boolean;
  onClose: () => void;
};

export const RequestFeatureUseDesktopModal: FC<RequestFeatureUseDesktopModalProps> = ({ shown, onClose }) => {
  const { t } = useTranslation();

  return (<Modal
    isOpen={shown}
    zIndex={MOVE_TO_TOP_Z_INDEX}
    onRequestClose={onClose}
    screen="mobile"
    dataHook={DATA_HOOKS.REQUEST_FEATURE_USE_DESKTOP_MODAL}
  >
    <CustomModalLayout
      className={css.requestFeatureOnlyDesktopModal}
      onCloseButtonClick={onClose}
      overflowY="none"
    >
      <Box direction="vertical" className={css.onlyDesktopModalContent}>
        <Text className={css.onlyDesktopModalTitle} size='medium' weight='bold'>{t('roadmap.desktop-only-modal.title')}</Text>
        <Button size='small' priority='primary' dataHook={DATA_HOOKS.REQUEST_FEATURE_USE_DESKTOP_MODAL_BUTTON} fullWidth={false} onClick={onClose} className={css.onlyDesktopModalButton}
        >{t('roadmap.desktop-only-modal.ok')}</Button>
      </Box>
    </CustomModalLayout>
  </Modal>
  );
};
