import React, { FunctionComponent } from 'react';
import { PageData } from '../PageTemplate';
import { ArticlePageSEO } from './ArticlePageSEO';
import { SearchResultsPageSEO } from './SearchResultsPageSEO';
import { CategoryPageSEO } from './CategoryPageSEO';
import { HomePageSEO } from './HomePageSEO';
import { BasicSeoElements } from './BasicSEO';
import { PageSeo, PageType, PAGES } from '../../types';
import { useTranslation } from '@wix/wix-i18n-config';

type SEOProps = {
  pageType: PageType;
  pageData: PageData;
  pageSeo: PageSeo;
};

export const SEO: FunctionComponent<SEOProps> = ({
  pageType,
  pageData,
  pageSeo,
}) => {
  const { t } = useTranslation();

  return (
    <>
      {(() => {
        switch (pageType) {
          case PAGES.KNOWN_ISSUES:
          case PAGES.CATEGORY:
            return (
              <CategoryPageSEO
                category={pageData.category}
                pageSeo={pageSeo}
                translations={pageData.translations}
              />
            );
          case PAGES.ARTICLE:
            return (
              <ArticlePageSEO article={pageData.article} pageSeo={pageSeo} />
            );
          case PAGES.SEARCH_RESULTS:
            return (
              <SearchResultsPageSEO pageSeo={pageSeo} term={pageData.term} />
            );
          case PAGES.HOMEPAGE:
            return <HomePageSEO pageSeo={pageSeo} />;
          case PAGES.ROADMAP:
            return <BasicSeoElements title={t('wix-product-roadmap-title')} />;
          case PAGES.PROFILE:
          case PAGES.TICKET:
            return (
              <BasicSeoElements
                title="Wix Support"
                robots={{
                  noArchive: false,
                  noFollow: true,
                  noIndex: true,
                }}
              />
            );
          default:
            return <BasicSeoElements title="Wix Support" />;
        }
      })()}
    </>
  );
};
