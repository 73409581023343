import React, { FunctionComponent } from 'react';
import { useRouter } from 'next/router';
import Head from 'next/head';
import { PRODUCTION_DOMAIN } from '../../../constants';

type BasicSeoElementsProps = {
  title?: string;
  description?: string;
  imageUrl?: string;
  robots?: {
    noArchive: boolean;
    noFollow: boolean;
    noIndex: boolean;
  };
};

export const BasicSeoElements: FunctionComponent<BasicSeoElementsProps> = ({
  title,
  description,
  imageUrl,
  robots,
}) => {
  const { locale, asPath } = useRouter();

  const robotsArr = [];
  if (robots?.noArchive) {
    robotsArr.push('noarchive');
  }
  if (robots?.noIndex) {
    robotsArr.push('noindex');
  }
  if (robots?.noFollow) {
    robotsArr.push('nofollow');
  }

  const href = `https://${PRODUCTION_DOMAIN}/${locale}${
    asPath !== '/' ? asPath : ''
  }`;

  return (
    <Head>
      <meta
        name="viewport"
        content="width=device-width, initial-scale=1.0, user-scalable=no"
      />
      <title>{title}</title>
      <link
        rel="icon"
        sizes="192x192"
        href="https://www.wix.com/favicon.ico"
        type="image/x-icon"
      />
      <link
        rel="apple-touch-icon"
        href="https://www.wix.com/favicon.ico"
        type="image/x-icon"
      />
      <link
        rel="shortcut icon"
        href="https://www.wix.com/favicon.ico"
        type="image/x-icon"
      />
      <base href={`//${process.env.domain}/${locale}`} />
      <meta httpEquiv="content-type" content="text/html; charset=UTF-8" />
      <meta httpEquiv="content-language" content={locale as string} />
      <meta
        name="google-site-verification"
        content="caM2lnCRLah4A1mGepO9qL_hg8cqHdh3UzqMkNMaNAA"
      />
      <meta name="description" content={description} />
      <meta name="og:description" content={description} />
      <meta name="twitter:description" content={description} />
      <meta name="og:title" content={title} />
      <meta property="og:url" content={href} />
      <meta property="og:type" content="website" />
      <meta property="og:image" content={imageUrl} />
      <meta name="twitter:title" content={title} />
      <meta name="twitter:image:src" content={imageUrl} />
      <link rel="canonical" href={href.split('?')[0]} />
      {robotsArr.length > 0 && (
        <meta name="robots" content={robotsArr.join(',')} />
      )}
    </Head>
  );
};
