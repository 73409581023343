import { ArticleResolution } from '@wix/answers-api';
import React from 'react';
import { ArticleSearchResultItem, VoteType } from '../types';
import { useExperiments } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS } from '../constants';
import { Context } from '../context';

const autoEnrollmentResolutions = [ArticleResolution.IN_DEVELOPMENT, ArticleResolution.PLANNED, ArticleResolution.COMING_SOON, ArticleResolution.PENDING_RELEASE];

export const useVoteType = (article: ArticleSearchResultItem) => {
  const { experiments } = useExperiments({ readOnly: true });
  const isAutoEnrollmentEnabled = experiments.enabled(EXPERIMENTS.SPECS.AUTO_ENROLLMENT);
  const { isWixStudioUser } = React.useContext(Context);

  const voteType = React.useMemo(() => {
    if (isAutoEnrollmentEnabled
      && isWixStudioUser && article.featureEnrollment?.featureId
      && article.resolution && autoEnrollmentResolutions.includes(article.resolution)
      && article.allowEnrollment) {
      return VoteType.ENROLLMENT;
    }
    if (article.resolution === ArticleResolution.COLLECTING_VOTES) {
      return VoteType.VOTE;
    }
    return VoteType.FOLLOW;
  }, [article.resolution, isAutoEnrollmentEnabled, article.featureEnrollment?.featureId, isWixStudioUser]);
  return voteType;
};
