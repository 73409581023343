import { useContext } from 'react';
import { helpCenterModals } from '@wix/bi-logger-new-help-center/v2';
import { useBI } from '../../hooks/useBI';
import { Context } from '../../context';
import { useRouter } from 'next/router';
import { PageType } from '../../types';
import { BI } from '../../constants';
import { OptInModalStatus } from '../../hooks/useOptInUtils';

type OptInModalBIData = {
  articleId: string;
  status: OptInModalStatus;
};

export const useOptInModalBi = (data: OptInModalBIData) => {
  const { sendBIEvent } = useBI();
  const context = useContext(Context);
  const { locale } = useRouter();

  const optInConfirmModalLoad = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        action_type: BI.ACTION_TYPE.MODAL_LOAD,
      })
    );
  };

  const modalOptInClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.OPT_IN,
      })
    );
  };

  const modalCancelOptInClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_CONFIRMATION,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.CANCEL,
      })
    );
  };

  const optInFailedModalLoad = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_LOAD,
      })
    );
  };

  const tryAgainClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.TRY_AGAIN,
      })
    );
  };

  const failedOkClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.OK,
      })
    );
  };

  const failedDismissClick = () => {
    const isOptInFailed = data.status === OptInModalStatus.OptInFailed;
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: isOptInFailed ? BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED : BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        screen_name: isOptInFailed ? BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_FAILED : BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.CLOSE,
      })
    );
  };

  const optOutFailedModalLoad = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_LOAD,
      })
    );
  };

  const tryAgainOptOutClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.TRY_AGAIN,
      })
    );
  };

  const optOutFailedOkClick = () => {
    void sendBIEvent(
      helpCenterModals({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        item_id: data.articleId,
        modal_name: BI.MODAL_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        screen_name: BI.SCREEN_NAME.FEATURE_REQUEST_ENROLLMENT_OPT_OUT_FAILED,
        action_type: BI.ACTION_TYPE.MODAL_CLICK,
        clicked_button_name: BI.CLICKED_ITEM_TYPES.CLOSE,
      })
    );
  };

  return { optInConfirmModalLoad, modalOptInClick, modalCancelOptInClick, optInFailedModalLoad, tryAgainClick, failedOkClick, failedDismissClick, optOutFailedModalLoad, tryAgainOptOutClick, optOutFailedOkClick };
};
