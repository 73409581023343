import { Box, Text, Tag, Divider } from '@wix/design-system';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../../dataHooks';
import { ArticleSearchResultItem } from '../../../../types';
import { InnerLink } from '../../../InnerLink';
import { RenderHTML } from '../../../RenderHTML';
import { ROADMAP_RESOLUTIONS } from '../../../SearchResults/SearchResultItem/SearchResultItemBadges';
import {
  EDITORS_NAMES,
  LOCALES,
  TOOLTIP_ENTER_DELAY,
} from '../../../../constants';
import { useRouter } from 'next/router';
import { useTranslation } from '@wix/wix-i18n-config';
import css from '../index.module.scss';

export type DeprecatedInternalSearchBarResultsProps = {
  articles: ArticleSearchResultItem[];
  inputText: string;
  focusedArticleIndex: number;
  onSearchResultClick: (article: ArticleSearchResultItem, index: number) => Promise<void>;
  setFocusedArticleIndex: (index: number) => void;
};

export const DeprecatedInternalSearchBarResults: FunctionComponent<
  DeprecatedInternalSearchBarResultsProps
> = ({
  articles,
  inputText,
  focusedArticleIndex,
  onSearchResultClick,
  setFocusedArticleIndex,
}) => {
  const { locale } = useRouter();
  const { t } = useTranslation();

  const tooltipProps = {
    enterDelay: TOOLTIP_ENTER_DELAY,
  };
  const getArticleTitle = (title: string) => {
    if (title.includes(':')) {
      const [firstPart, secondPart] = title.split(':');
      const isEditor = EDITORS_NAMES.some((editor) =>
        firstPart.includes(editor)
      );
      if (isEditor) {
        return (
          <>
            <b>{firstPart}</b>:{secondPart}
          </>
        );
      }
    }
    return title;
  };

  const getArticleTagLabel = (article: ArticleSearchResultItem) => {
    if (
      article.resolution &&
      ROADMAP_RESOLUTIONS.includes(article.resolution)
    ) {
      const roadmapKey =
        locale === LOCALES.EN
          ? 'search-bar-results.type.roadmap'
          : 'feature.request.title';
      return t(roadmapKey);
    } else {
      return t(`search-bar-results.type.${article.type}`);
    }
  };

  return (
    <>
      <Box className={css.searchBarResultsList} direction="vertical">
        {articles.map((result, index) => {
          const resultItem = result as ArticleSearchResultItem;
          return (
            <InnerLink
              key={resultItem.id}
              href={resultItem.uri}
              onClick={async () => {
                await onSearchResultClick(
                  resultItem,
                  index
                );
              }}
              dataHook={DATA_HOOKS.SEARCH_BAR_RESULT}
              className={classNames(css.searchBarResult, {
                [css.focused]: focusedArticleIndex === index,
              })}
              onMouseEnter={() => setFocusedArticleIndex(index)}
            >
              <Box
                className={css.searchBarResultContent}
                verticalAlign="middle"
                align="space-between"
              >
                <Box direction="vertical" className={css.searchBarResultText}>
                  <Text
                    size="medium"
                    weight="thin"
                    ellipsis
                    tooltipProps={tooltipProps}
                    zIndex={10000}
                    className={css.searchBarResultTitle}
                    dataHook={DATA_HOOKS.SEARCH_BAR_RESULT_TITLE}
                  >
                    {getArticleTitle(result.title)}
                  </Text>
                  {
                    <RenderHTML
                      html={result.description}
                      textElement={{
                        className: css.searchBarResultDescription,
                        weight: 'thin',
                        size: 'small',
                        dataHook: DATA_HOOKS.SEARCH_BAR_RESULT_DESCRIPTION,
                        ellipsis: true,
                        showTooltip: false,
                      }}
                    />
                  }
                </Box>
                <Tag
                  id={resultItem.id}
                  size="tiny"
                  removable={false}
                  theme="lightOutlined"
                  className={css.searchBarResultTag}
                >
                  {getArticleTagLabel(resultItem)}
                </Tag>
              </Box>
            </InnerLink>
          );
        })}
      </Box>
      <Divider />
      <div aria-live="assertive" key={inputText} className={css.srOnly}>
        <>{t('searchbar.results.reader', { count: articles.length })}</>
      </div>
    </>
  );
};
