import webBiLogger from '@wix/web-bi-logger';
import { useCallback, useContext } from 'react';
import { LogBI } from '../types';
import {
  getClientIdOrGenerate,
  hasWixClientId,
  setWixClientId,
} from '../utils/login';
import { useSessionBI } from './useSessionBI';
import { Context } from '../context';

export const useBI = () => {
  const { getSessionBI } = useSessionBI();
  const { loggedInAccountId } = useContext(Context);
  const sendBIEvent = useCallback(
    async (event: LogBI): Promise<void> => {
      if (!hasWixClientId()) {
        setWixClientId();
      }

      const biLogger = webBiLogger
        .factory()
        .withNonEssentialContext({
          clientId: getClientIdOrGenerate(),
        })
        .logger();
      getSessionBI(event)
        .then(async (value) => {
          event.params.self_service_session_id = value;
          event.params.target_account_id = loggedInAccountId;
          await biLogger.report(event);
        })
        .catch(() => biLogger.report(event));
    },
    [getSessionBI, loggedInAccountId]
  );

  return { sendBIEvent };
};
