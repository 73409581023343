import { pageClick } from '@wix/bi-logger-customer-care-data/v2';
import { useContext } from 'react';
import { useBI } from '../../../hooks/useBI';
import { Context } from '../../../context';
import { useRouter } from 'next/router';
import { PageType } from '../../../types';
import { BI } from '../../../constants';

type optInClickData = {
  articleId: string;
  clickedText: string;
};

export const useRoadmapVoteBi = () => {
  const { sendBIEvent } = useBI();
  const context = useContext(Context);
  const { locale } = useRouter();

  const optInClick = (data: optInClickData) => {
    void sendBIEvent(
      pageClick({
        source_name: context.pageType === PageType.Article ? BI.SOURCE_NAMES.ARTICLE : BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale,
        clicked_item_id: data.articleId,
        item_type: BI.ITEM_TYPES.FEATURE_REQUEST_ENROLLMENT,
        clicked_text: data.clickedText,
      })
    );
  };

  return { optInClick };
};
