import React, { FunctionComponent } from 'react';
import { BasicSeoElements } from '../BasicSEO';
import { PageSeo } from '../../../types';
import { LOCALES, PRODUCTION_DOMAIN } from '../../../constants';
import Head from 'next/head';

type SearchResultsPageSEOProps = {
  pageSeo: PageSeo;
  term?: string;
};

export const SearchResultsPageSEO: FunctionComponent<
  SearchResultsPageSEOProps
> = ({ pageSeo, term = '' }: SearchResultsPageSEOProps) => (
  <>
    <BasicSeoElements
      title={pageSeo?.title}
      description={pageSeo?.description}
      imageUrl={pageSeo?.imageUrl}
      robots={pageSeo?.robots}
    />
    <Head>
      {Object.values(LOCALES).map((targetLocale) => (
        <link
          rel="alternate"
          hrefLang={targetLocale}
          href={`https://${PRODUCTION_DOMAIN}/${targetLocale}/search?term=${term}`}
          key={`/${targetLocale}/search?term=${term}`}
        />
      ))}
      <link
        rel="alternate"
        hrefLang="x-default"
        href={`https://${PRODUCTION_DOMAIN}/${LOCALES.EN}/search?term=${term}`}
      />
    </Head>
  </>
);
