import { Notification, NotificationTheme } from '@wix/design-system';
import { useHttpClient } from '@wix/fe-essentials-standalone';
import { useTranslation } from '@wix/wix-i18n-config';
import { useRouter } from 'next/router';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import {
  BI,
  LOCALES,
  WIX_STATUS_DOMAIN,
  WIX_STATUS_PATH,
} from '../../../constants';
import { DATA_HOOKS } from '../../../dataHooks';
import { RenderHTML } from '../../RenderHTML';
import { Incident, IncidentImpact, IncidentsResponse } from './types';

import {
  helpCenterBannerClick,
  helpCenterBannerView,
} from '@wix/bi-logger-new-help-center/v2';

import { useBI } from '../../../hooks/useBI';
import { PageType } from '../../../types';
import { mapPageTypeToSourceName } from '../../../utils/bi';
import { isSledUserAgent } from '../../../utils/userAgent';

export type StatusPageNotificationProps = {
  pageType: PageType;
};

export const StatusPageNotification: FunctionComponent<
  StatusPageNotificationProps
> = ({ pageType }) => {
  const { t } = useTranslation();
  const { locale } = useRouter();
  const { sendBIEvent } = useBI();

  const [resolvedIncidents, setResolvedIncidents] = useState<Incident[]>([]);
  const [openIncidents, setOpenIncidents] = useState<Incident[]>([]);
  const [isFetchedIncidents, setIsFetchedIncidents] = useState(false);
  const ref = useRef<null | HTMLDivElement>(null);

  const httpClient = useHttpClient();

  const isResolvedInLastTenMinutes = (resolvedAt: string) => {
    const resolvedAtDate = new Date(resolvedAt).getTime();
    const currentDate = new Date().getTime();
    const tenMinutes = 600000;
    return currentDate - resolvedAtDate < tenMinutes;
  };

  const filterIncidents = useCallback(
    (
      incidents: Incident[]
    ): { newOpenIncidents: Incident[]; newResolvedIncidents: Incident[] } => {
      const newResolvedIncidents: Incident[] = [];
      const newOpenIncidents: Incident[] = [];
      for (const incident of incidents) {
        if (
          !incident.name.toLowerCase().includes('~kb~') &&
          incident.impact !== 'none'
        ) {
          if (!incident.resolved_at) {
            newOpenIncidents.push(incident);
          } else if (isResolvedInLastTenMinutes(incident.resolved_at)) {
            newResolvedIncidents.push(incident);
          }
        }
      }
      return {
        newOpenIncidents,
        newResolvedIncidents,
      };
    },
    []
  );

  const fetchIncidents = useCallback(async (): Promise<Incident[]> => {
    if (isSledUserAgent()) {
      return [];
    }
    const {
      data: { incidents },
    } = await httpClient.get<IncidentsResponse>(
      `${WIX_STATUS_DOMAIN}${WIX_STATUS_PATH}`
    );
    return incidents;
  }, [httpClient]);

  const getTheme = (): NotificationTheme | null => {
    if (openIncidents.length > 1) {
      return 'error';
    } else if (openIncidents.length === 1) {
      return openIncidents[0].impact === IncidentImpact.Critical
        ? 'error'
        : 'warning';
    } else if (resolvedIncidents.length > 0) {
      return 'success';
    }
    return null;
  };

  const getText = (): string => {
    if (openIncidents.length > 1) {
      return t('notification.bar.status.page.all.open');
    } else if (openIncidents.length === 1) {
      return t('notification.bar.status.page.one.resolved.or.open', {
        name: openIncidents[0].name,
      });
    } else if (resolvedIncidents.length > 1) {
      return t('notification.bar.status.page.all.resolved');
    } else if (resolvedIncidents.length === 1) {
      return t('notification.bar.status.page.one.resolved.or.open', {
        name: resolvedIncidents[0].name,
      });
    }
    return '';
  };

  const onNotificationClick = async (e: any) => {
    const shownIncidents = [...openIncidents, ...resolvedIncidents];
    if (shownIncidents.length > 0 && e.target.tagName === 'A') {
      await sendBIEvent(
        helpCenterBannerClick({
          banner_language: locale,
          banner_name:
            shownIncidents.length > 1
              ? 'multiple issues'
              : shownIncidents[0].name,
          banner_text: getText(),
          banner_type: BI.BANNERS_TYPE.STATUS_PAGE,
          item_id: shownIncidents.map((incident) => incident.id).join(','),
          is_resolved: shownIncidents.every(
            (incident) => !!incident.resolved_at
          ),
          item_type: BI.ITEM_TYPES.INCIDENT_BANNER,
          source_name: mapPageTypeToSourceName(pageType),
          clicked_url: e.target.href,
          clicked_text: e.target.innerText,
          clicked_item_type: BI.ITEM_TYPES.INCIDENT_BANNER,
        })
      );
    }
  };

  const onNotificationView = useCallback(
    async (incidents: Incident[]) => {
      if (incidents && incidents.length > 0) {
        await sendBIEvent(
          helpCenterBannerView({
            banner_name:
              incidents.length > 1 ? 'multiple issues' : incidents[0].name,
            banner_language: locale,
            banner_text: getText(),
            banner_type: BI.BANNERS_TYPE.STATUS_PAGE,
            item_id: incidents.map((incident) => incident.id).join(','),
            is_resolved: incidents.every((incident) => !!incident.resolved_at),
            item_type: BI.ITEM_TYPES.INCIDENT_BANNER,
            source_name: mapPageTypeToSourceName(pageType),
            has_cta: true,
          })
        );
      }
    },
    [locale, pageType, getText]
  );

  useEffect(() => {
    void (async () => {
      const shownIncidents = [...openIncidents, ...resolvedIncidents];
      if (!!shownIncidents && getTheme() && locale === LOCALES.EN) {
        await onNotificationView(shownIncidents);
      }
    })();
  }, [getTheme, onNotificationView, openIncidents, resolvedIncidents]);

  useEffect(() => {
    void (async () => {
      if (isFetchedIncidents) {
        return;
      }
      const incidents = await fetchIncidents();
      const { newOpenIncidents, newResolvedIncidents } =
        filterIncidents(incidents);
      setResolvedIncidents(newResolvedIncidents);
      setOpenIncidents(newOpenIncidents);
      setIsFetchedIncidents(true);
    })();
  }, [fetchIncidents, filterIncidents, isFetchedIncidents]);

  const theme = getTheme();
  const text = getText();
  return theme && text ? (
    <Notification
      theme={theme}
      show={locale === LOCALES.EN}
      dataHook={DATA_HOOKS.STATUS_PAGE_NOTIFICATION}
    >
      <Notification.TextLabel ellipsis={false} textAlign="center">
        <div ref={ref} onClick={onNotificationClick}>
          <RenderHTML html={text} />
        </div>
      </Notification.TextLabel>
      <Notification.CloseButton />
    </Notification>
  ) : null;
};
