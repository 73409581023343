import React, { FunctionComponent, useCallback, useContext, useEffect } from 'react';
import { Box, Text, ToggleButton, Loader } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import Heart from 'wix-ui-icons-common/Heart';
import HeartFilled from 'wix-ui-icons-common/HeartFilled';
import ThumbsUp from 'wix-ui-icons-common/ThumbsUp';
import ThumbsUpFilled from 'wix-ui-icons-common/ThumbsUpFilled';
import { Flag, FlagFilled } from 'wix-ui-icons-common';
import { ArticleResolution, ArticleSortType } from '@wix/answers-api';
import { DATA_HOOKS } from '../../../dataHooks';
import { ArticleSearchResultItem, RoadmapETAorVotedFilter, VoteType } from '../../../types';
import { FilterToName, sortTypeToName, tabToName } from '../../../utils/roadmap';
import css from './index.module.scss';
import { voteUnVoteForWishList } from '@wix/bi-logger-new-help-center/v2';
import { COLORS } from '../../../utils/theme';
import { useBI } from '../../../hooks/useBI';
import { loginWithAnswersSSO } from '../../../utils/login';
import { BI } from '../../../constants';
import { Context } from '../../../context';
import { isMobile } from '../../../utils/userAgent';
import { useVoteType } from '../../../hooks/useVoteType';
import { OptInModalStatus, useOptInUtils } from '../../../hooks/useOptInUtils';
import { OptInModal } from '../../OptInModal';
import classNames from 'classnames';
import { useRoadmapVoteBi } from './bi';

export const voteTypeToBiVoteType = {
  [VoteType.FOLLOW]: BI.VOTE_TYPE.FEATURE_REQUEST_FOLLOW,
  [VoteType.VOTE]: BI.VOTE_TYPE.FEATURE_REQUEST_VOTE,
  [VoteType.ENROLLMENT]: BI.VOTE_TYPE.FEATURE_REQUEST_ENROLLMENT,
};

export type RoadmapVoteProps = {
  article: ArticleSearchResultItem;
  followArticle: (articleId: string) => void;
  unFollowArticle: (articleId: string) => void;
  locale: string;
  filter?: RoadmapETAorVotedFilter;
  sortType?: ArticleSortType;
  isFollowingArticle: boolean;
  articlePopulationStatus: Record<string, boolean>;
  togglePopulationStatus: (featureId: string) => void;
};
export const LABELS_LENGTH = 3;

export const MAX_PREVIEW_SIZE = 300;

const RoadmapVote: FunctionComponent<RoadmapVoteProps> = ({
  article,
  followArticle,
  unFollowArticle,
  locale,
  filter,
  sortType,
  isFollowingArticle,
  articlePopulationStatus,
  togglePopulationStatus,
}: RoadmapVoteProps) => {
  const { t } = useTranslation();
  const { sendBIEvent } = useBI();
  const [showVoteContent, setShowVoteContent] = React.useState(false);
  const [isFollowing, setIsFollowing] = React.useState(false);
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { isLoggedInUser } = context;
  const voteType = useVoteType(article);
  const [optInModalStatus, setOptInModalStatus] = React.useState<OptInModalStatus>(OptInModalStatus.Closed);
  const { optIn, optOut } = useOptInUtils(article, locale, togglePopulationStatus, setOptInModalStatus, setShowVoteContent);
  const biLogger = useRoadmapVoteBi();

  const isOptedIn = React.useMemo(() => {
    if (!article.featureEnrollment?.featureId) { return false; }
    return articlePopulationStatus[article.featureEnrollment.featureId];
  }, [article.featureEnrollment?.featureId, articlePopulationStatus]);

  const isOptInStatusLoading = React.useMemo(() => voteType === VoteType.ENROLLMENT && Object.keys(articlePopulationStatus).length === 0, [voteType, articlePopulationStatus]);

  const isPreviousVote = useCallback((): boolean => isFollowingArticle, [isFollowingArticle]);

  useEffect(() => {
    setIsFollowing(isPreviousVote());
  }, [isPreviousVote]);

  const clickFollowArticle = async () => {
    if (isLoggedInUser) {
      followArticle(article.id);
      setIsFollowing(true);
      setShowVoteContent(true);
      await sendVoteBiEvent();
    } else {
      loginWithAnswersSSO(location.href, locale);
    }
  };
  const clickUnFollowArticle = async () => {
    unFollowArticle(article.id);
    setIsFollowing(false);
    setShowVoteContent(false);
    await sendVoteBiEvent();
  };

  const sendVoteBiEvent = async () =>
    sendBIEvent(
      voteUnVoteForWishList({
        source_name: BI.SOURCE_NAMES.ROADMAP,
        kb_lang: locale as string,
        item_id: article.id,
        tab: article.resolution ? tabToName(article.resolution) : undefined,
        sort_by: sortType && sortTypeToName(sortType),
        filter_by: filter && FilterToName[filter],
        thumbs_up: !isFollowing,
        vote_type: voteTypeToBiVoteType[voteType],
      })
    );

  const onClick = () => {
    switch (voteType) {
      case VoteType.FOLLOW:
      case VoteType.VOTE:
        if (isFollowing) {
          return clickUnFollowArticle();
        }
        return clickFollowArticle();
      case VoteType.ENROLLMENT:
        if (isOptedIn) {
          return optOut();
        }
        biLogger.optInClick({ articleId: article.id, clickedText: t('roadmap.article-item.opt-in') });
        return setOptInModalStatus(OptInModalStatus.Confirm);
    }
  };

  const isToggleButtonSelected = React.useMemo(() => {
    if (voteType === VoteType.ENROLLMENT) {
      return isOptedIn;
    }
    return isFollowing;
  }, [voteType, isFollowing, isOptedIn]);
  const isPreLaunch = article.resolution && [ArticleResolution.COMING_SOON, ArticleResolution.PENDING_RELEASE].includes(article.resolution);
  const voteButtonDisabled = React.useMemo(() => (isOptedIn && voteType === VoteType.ENROLLMENT && isPreLaunch) || isOptInStatusLoading, [isOptedIn, voteType, article.resolution, isOptInStatusLoading]);
  const { text: buttonText, icon: buttonIcon } = React.useMemo(() => {
    switch (voteType) {
      case VoteType.FOLLOW:
        return isFollowing
          ? { text: t('roadmap.article-item.following'), icon: <HeartFilled /> }
          : { text: t('roadmap.article-item.follow'), icon: <Heart className={css.followIcon} /> };
      case VoteType.VOTE:
        return isFollowing
          ? { text: t('roadmap.article-item.voted'), icon: <ThumbsUpFilled /> }
          : { text: t('roadmap.article-item.vote'), icon: <ThumbsUp /> };
      case VoteType.ENROLLMENT:
        return isOptedIn
          ? { text: t('roadmap.article-item.opted-in'), icon: <FlagFilled /> }
          : { text: t('roadmap.article-item.opt-in'), icon: <Flag /> };
    }
  }, [voteType, isFollowing, t, isOptedIn]);
  const buttonLabel = React.useMemo(() => <span className={classNames(css.followButtonLabel, { [css.followButtonLabelDisabled]: voteButtonDisabled })}>{buttonText}</span>, [buttonText, voteButtonDisabled]);
  const voteContent = React.useMemo(() => {
    if (voteButtonDisabled && !isOptInStatusLoading) {
      return (
        <Text className={css.voteContent} textAlign="center" size="tiny">
          {t('roadmap.article-item.vote-content-enrollment-disabled')}
        </Text>
      );
    }
    if (showVoteContent) {
      if (isMobileView && voteType !== VoteType.ENROLLMENT) {
        return null;
      }
      return (
        <Text className={css.voteContent} textAlign="center" size="tiny">
          {voteType === VoteType.ENROLLMENT ? t('roadmap.article-item.enrollment-vote-content') : t('roadmap.article-item.vote-content')}
        </Text>
      );
    }
    return null;
  }, [showVoteContent, t, voteButtonDisabled, isOptInStatusLoading]);

  if (isMobileView) {
    return (
      <Box direction='vertical' align='right' flexShrink={1}>
        <ToggleButton
          className={isToggleButtonSelected ? css.mobileVotedButton : undefined}
          onClick={onClick}
          size="small"
          selected={isToggleButtonSelected}
          shape="round"
          border={true}
          labelPlacement="end"
          disabled={voteButtonDisabled}
          dataHook={DATA_HOOKS.FOLLOW_VOTE_ARTICLE_BTN}
        >
          {isOptInStatusLoading ? <Loader size='tiny' /> : buttonIcon}
        </ToggleButton>
        <OptInModal status={optInModalStatus} setStatus={setOptInModalStatus} article={article} onOptIn={optIn} onOptOut={optOut} />
        {voteContent}
      </Box>
    );
  }

  return (
    <Box
      minHeight={72}
      height={'100%'}
      direction="vertical"
      className={css.followArticle}
      align="center"
    >
      <ToggleButton
        shape="pill"
        color={COLORS.B50}
        border={true}
        dataHook={DATA_HOOKS.FOLLOW_VOTE_ARTICLE_BTN}
        labelValue={buttonLabel}
        selected={isToggleButtonSelected}
        labelPlacement="end"
        disabled={voteButtonDisabled}
        onClick={onClick}
      >
        {isOptInStatusLoading ? <Loader size='tiny' /> : buttonIcon}
      </ToggleButton>
      {voteContent}
      <OptInModal status={optInModalStatus} setStatus={setOptInModalStatus} article={article} onOptIn={optIn} onOptOut={optOut} />
    </Box>
  );
};

export default RoadmapVote;
