import React, { FunctionComponent } from 'react';
import { sanitizeHtml } from '../../utils/text';
import { Heading, Text } from '@wix/design-system';

type RenderHTMLProps = {
  html: string;
  className?: string;
  dataHook?: string;
  textElement?: React.ComponentProps<typeof Text>;
  headingElement?: React.ComponentProps<typeof Heading>;
  onClick?: (e: React.MouseEvent<HTMLElement>) => Promise<void>;
};

export const RenderHTML: FunctionComponent<RenderHTMLProps> = ({
  html,
  className,
  dataHook,
  textElement,
  headingElement,
  onClick
}: RenderHTMLProps) => {
  if (textElement) {
    return (
      <Text
        className={className}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
        dataHook={dataHook}
        {...(onClick ? { onClick } : {})}
        {...textElement}
      />
    );
  } else if (headingElement) {
    return (
      <Heading
        className={className}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
        dataHook={dataHook}
        {...(onClick ? { onClick } : {})}
        {...headingElement}
      />
    );
  } else {
    return (
      <div
        className={className}
        dangerouslySetInnerHTML={{ __html: sanitizeHtml(html) }}
        data-hook={dataHook}
        {...(onClick ? { onClick } : {})}
      />
    );
  }
};
