import React, { FunctionComponent } from 'react';
import { Box } from '@wix/design-system';
import { LanguageSupportedNotification } from './LanguageSupportedNotification';
import { StatusPageNotification } from './StatusPageNotification';
import { PageType } from '../../types';
import css from './index.module.scss';

type NotificationBarProps = {
  pageType: PageType;
};

export const NotificationBar: FunctionComponent<NotificationBarProps> = ({
  pageType,
}) => (
    <Box className={css.notificationBar} direction="vertical">
      {pageType !== PageType.RoadMap && (
        <LanguageSupportedNotification pageType={pageType} />
      )}
      <StatusPageNotification pageType={pageType} />
    </Box>
  );
