import { Box, Heading, Text } from '@wix/design-system';
import { useTranslation } from '@wix/wix-i18n-config';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../dataHooks';
import css from './index.module.scss';

export type EmptySearchResultsProps = {
  inputText: string;
};

export const EmptySearchResults: FunctionComponent<EmptySearchResultsProps> = ({
  inputText,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      verticalAlign="middle"
      direction="vertical"
      align="center"
      className={css.EmptySearchResults}
      dataHook={DATA_HOOKS.EMPTY_SEARCH_RESULTS}
    >
      <Heading
        appearance="H3"
        className={css.header}
        dataHook={DATA_HOOKS.EMPTY_SEARCH_RESULTS_HEADER}
      >
        {t('searchbar.empty-search-results.no-results-match', { inputText })}
      </Heading>
      <Text
        size="small"
        weight="thin"
        className={css.message}
        dataHook={DATA_HOOKS.EMPTY_SEARCH_RESULTS_MESSAGE}
      >
        {t('searchbar.empty-search-results.try-again')}
      </Text>
    </Box>
  );
};
