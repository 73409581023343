import { HttpService } from '@wix/answers-api';
import { HttpClient } from '@wix/http-client';

export const createHttpService = (): HttpService => {
  const httpClient = new HttpClient();
  const http: HttpService = {
    get: async <T>(url: string) =>
      httpClient.get(url).then((req) => req.data as T),
    post: async <T>(url: string, body: any) =>
      httpClient.post(url, body).then((req) => req.data as T),
    put: async <T>(url: string, body: any) =>
      httpClient.put(url, body).then((req) => req.data as T),
    delete: async <T>(url: string) =>
      httpClient.delete(url).then((req) => req.data as T),
  };
  return http;
};
