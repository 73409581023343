import { Box, Text } from '@wix/design-system';
import classNames from 'classnames';
import { FunctionComponent } from 'react';
import { DATA_HOOKS } from '../../../../dataHooks';
import { InnerLink } from '../../../InnerLink';
import { useTranslation } from '@wix/wix-i18n-config';
import css from '../index.module.scss';

export type ResultsFooterProps = {
  inputText: string;
  resultsCount: number;
  onClickAllResults: () => Promise<void>;
  focusedArticleIndex: number;
};

export const ResultsFooter: FunctionComponent<ResultsFooterProps> = ({
  inputText,
  resultsCount,
  onClickAllResults,
  focusedArticleIndex,
}) => {
  const { t } = useTranslation();

  return (
    <Box
      className={classNames(css.searchBarResultsFooter, {
        [css.focused]: focusedArticleIndex === resultsCount,
      })}
      direction="vertical"
      align="left"
    >
      <InnerLink
        href={`/search?term=${inputText}`}
        className={css.seeMoreResults}
        dataHook={DATA_HOOKS.SHOW_ALL_SEARCH_RESULTS}
        onClick={onClickAllResults}
      >
        <Text size="medium" weight="thin" className={css.seeMoreResultsText}>
          <>
            {' '}
            {t('searchbar.see.all.results')} ‘{inputText}’{' '}
          </>
        </Text>
      </InnerLink>
    </Box>
  );
};
