import React, { FunctionComponent, useEffect } from 'react';
import { Box, Text } from '@wix/design-system';
import { DeprecatedLoaderIcon } from './DeprecatedLoaderIcon';
import css from './index.module.scss';
import classNames from 'classnames';
import { useExperiments, useTranslation } from '@wix/fe-essentials-standalone';
import { EXPERIMENTS } from '../../constants';
import { LoaderIcon } from './LoaderIcon';
import { DATA_HOOKS } from '../../dataHooks';

type LoaderProps = {
  isLoading: boolean;
};

export const getRandomLoadingMessage = (t: (key: string) => string): string => {
  let idx = 0;
  const loadingMessages = [];

  while (t(`loader.text-${idx}`) !== `loader.text-${idx}`) {
    loadingMessages.push(t(`loader.text-${idx}`));
    idx++;
  }

  return loadingMessages[Math.floor(Math.random() * loadingMessages.length)];
};

export const Loader: FunctionComponent<LoaderProps> = ({ isLoading }) => {
  const { t } = useTranslation();
  const { experiments } = useExperiments({ readOnly: true });
  const [loadingMessage, setLoadingMessage] = React.useState<string>(getRandomLoadingMessage(t) || '');
  const showNewLoader = experiments.enabled(EXPERIMENTS.SPECS.SHOW_NEW_HC_LOADER);

  useEffect(() => {
    if (isLoading) {
      setLoadingMessage(getRandomLoadingMessage(t));
    }
  }, [isLoading, t]);

  return (
    <Box
      className={classNames(css.wrapper, { [css.active]: isLoading })}
      dataHook={DATA_HOOKS.HC_LOADER}
      align='center'
      verticalAlign='middle'
      direction='vertical'
    >
      {showNewLoader ? (
        <>
          <LoaderIcon />
          <Box margin='-10px'><Text size='small' weight='thin' className={css.loadingMessage}>{loadingMessage}</Text></Box>
        </>
      ) : <DeprecatedLoaderIcon />}
    </Box>
  );
};

