import { ArticleResolution, FeatureRequestResolutions } from '@wix/answers-api';
import { LOCALES } from '../constants';
import { statusToClass } from './roadmap';

const shouldLocalize = (locale?: string) => locale !== LOCALES.EN;

const resolutionTransformLocalize = (
  resolutionToTransform: ArticleResolution,
  locale?: string
): ArticleResolution => {
  if (shouldLocalize(locale)) {
    switch (resolutionToTransform) {
      case ArticleResolution.COLLECTING_VOTES:
      case ArticleResolution.PLANNED:
      case ArticleResolution.IN_DEVELOPMENT:
      case ArticleResolution.COMING_SOON:
        return ArticleResolution.COLLECTING_VOTES;
      default:
        return resolutionToTransform;
    }
  } else {
    return resolutionToTransform;
  }
};

const statusToClassLocalize = (
  locale?: string
): Record<FeatureRequestResolutions, string> => {
  const localizedStatusToClass = {
    [ArticleResolution.COLLECTING_VOTES]: 'collecting-votes',
    [ArticleResolution.PLANNED]: 'collecting-votes',
    [ArticleResolution.IN_DEVELOPMENT]: 'collecting-votes',
    [ArticleResolution.COMING_SOON]: 'collecting-votes',
    [ArticleResolution.PENDING_RELEASE]: 'collecting-votes',
    [ArticleResolution.RELEASED]: 'released',
  };

  if (shouldLocalize(locale)) {
    return localizedStatusToClass;
  } else {
    return statusToClass;
  }
};

export { resolutionTransformLocalize, shouldLocalize, statusToClassLocalize };
