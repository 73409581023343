import { MessageModalLayout, Modal, Text, ModalMobileLayout, Box, Button } from '@wix/design-system';
import { useContext, useEffect, useMemo } from 'react';
import { DATA_HOOKS } from '../../dataHooks';
import { Context } from '../../context';
import { ArticleSearchResultItem } from '../../types';
import { useTranslation } from '@wix/wix-i18n-config';
import { ArticleResolution } from '@wix/answers-api';
import { isMobile } from '../../utils/userAgent';
import { OptInModalStatus } from '../../hooks/useOptInUtils';
import css from './index.module.scss';
import { useOptInModalBi } from './bi';

export type OptInModalProps = {
  status: OptInModalStatus;
  article: ArticleSearchResultItem;
  setStatus: (status: OptInModalStatus) => void;
  onOptIn: () => void;
  onOptOut: () => void;
};

export const OptInModal = ({
  status,
  article,
  setStatus,
  onOptIn,
  onOptOut,
}: OptInModalProps) => {
  const context = useContext(Context);
  const isMobileView = isMobile(context);
  const { t } = useTranslation();
  const biLogger = useOptInModalBi({ articleId: article.id, status });

  const closeModal = () => setStatus(OptInModalStatus.Closed);

  const { dataHook, titleText, contentText, theme, secondaryButtonText, primaryButtonText, secondaryButtonAction, primaryButtonAction } = useMemo(() => {
    if (status === OptInModalStatus.OptInFailed) {
      return {
        dataHook: DATA_HOOKS.OPT_IN_FAIL_MODAL,
        titleText: t('roadmap.article-item.opt-in-fail-modal.title'),
        contentText: t('roadmap.article-item.opt-in-fail-modal.content'),
        theme: 'destructive' as const,
        secondaryButtonText: t('roadmap.article-item.opt-in-fail-modal.cta-secondary'),
        primaryButtonText: t('roadmap.article-item.opt-in-fail-modal.cta-primary'),
        secondaryButtonAction: () => {
          biLogger.failedOkClick();
          closeModal();
        },
        primaryButtonAction: () => {
          biLogger.tryAgainClick();
          onOptIn();
        }
      };
    }
    if (status === OptInModalStatus.OptOutFailed) {
      return {
        dataHook: DATA_HOOKS.OPT_OUT_FAIL_MODAL,
        titleText: t('roadmap.article-item.opt-out-fail-modal.title'),
        contentText: t('roadmap.article-item.opt-out-fail-modal.content'),
        theme: 'destructive' as const,
        secondaryButtonText: t('roadmap.article-item.opt-out-fail-modal.cta-secondary'),
        primaryButtonText: t('roadmap.article-item.opt-out-fail-modal.cta-primary'),
        secondaryButtonAction: () => {
          biLogger.optOutFailedOkClick();
          closeModal();
        },
        primaryButtonAction: () => {
          biLogger.tryAgainOptOutClick();
          onOptOut();
        }
      };
    }
    const isPreLaunch = article.resolution && [ArticleResolution.COMING_SOON, ArticleResolution.PENDING_RELEASE].includes(article.resolution);
    return {
      dataHook: DATA_HOOKS.OPT_IN_MODAL,
      titleText: t('roadmap.article-item.opt-in-modal.opt-in-title', { articleTitle: article.title }),
      contentText: isPreLaunch ? t('roadmap.article-item.opt-in-modal.pre-launch-content') : t('roadmap.article-item.opt-in-modal.planned-content'),
      theme: 'standard' as const,
      secondaryButtonText: t('roadmap.article-item.opt-in-modal.cancel'),
      primaryButtonText: t('roadmap.article-item.opt-in-modal.opt-in'),
      secondaryButtonAction: () => {
        biLogger.modalCancelOptInClick();
        closeModal();
      },
      primaryButtonAction: () => {
        biLogger.modalOptInClick();
        onOptIn();
      }
    };
  }, [status, article, onOptIn, biLogger, t, onOptOut, closeModal]);

  const onDismiss = () => {
    biLogger.failedDismissClick();
    closeModal();
  };

  useEffect(() => {
    if (status === OptInModalStatus.Confirm) {
      return biLogger.optInConfirmModalLoad();
    }
    if (status === OptInModalStatus.OptInFailed) {
      return biLogger.optInFailedModalLoad();
    }
    if (status === OptInModalStatus.OptOutFailed) {
      return biLogger.optOutFailedModalLoad();
    }
  }, [status]);

  if (status === OptInModalStatus.Closed) {
    return null;
  }

  if (isMobileView) {
    return (
      <Modal isOpen={true} dataHook={dataHook} screen='mobile'>
        <ModalMobileLayout
          dataHook={dataHook}
          onCloseButtonClick={onDismiss}
          title={<Text weight="bold" className={css.mobileModalTitle}>{titleText}</Text>}
          content={<Text weight="thin" secondary>{contentText}</Text>}
          footer={
            <Box align="right">
              <Box marginRight="12px">
                <Button skin={theme} onClick={secondaryButtonAction} priority="secondary">{secondaryButtonText}</Button>
              </Box>
              <Button skin={theme} onClick={primaryButtonAction}>{primaryButtonText}</Button>
            </Box>
          }
        />
      </Modal>
    );
  }
  return (
    <Modal isOpen={true} dataHook={dataHook}>
      <MessageModalLayout
        theme={theme}
        className={css.optInMessageModal}
        onCloseButtonClick={onDismiss}
        primaryButtonText={primaryButtonText}
        primaryButtonOnClick={primaryButtonAction}
        secondaryButtonText={secondaryButtonText}
        secondaryButtonOnClick={secondaryButtonAction}
        title={titleText}
        content={<Text>{contentText}</Text>}
      />
    </Modal >
  );
};
