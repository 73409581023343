import React, { useEffect, useState } from 'react';

export const useIsIntersecting = (ref: React.MutableRefObject<any>, marginTop = '0px') => {
  const [scrollState, setScrollState] = useState(false);

  useEffect(() => {
    const observer = new window.IntersectionObserver((entries) => {
      if (!entries[0].isIntersecting) {
        setScrollState(true);
      } else {
        setScrollState(false);
      }
    }, {rootMargin: `${marginTop} 0px 0px 0px`}) ;
    if (ref.current) {
      observer.observe(ref.current);
    }

    return () => {
      observer.disconnect();
    };
  }, [ref, marginTop]);

  return scrollState;
};
