interface Page {
  page: {
    id: string;
    name: string;
    time_zone: string;
    updated_at: string;
    url: string;
  };
}

interface Incidents {
  incidents: Incident[];
}

export enum IncidentImpact {
  Critical = 'critical',
  Major = 'major',
  Minor = 'minor',
  None = 'none',
}

export enum IncidentStatus {
  Identified = 'identified',
  Investigating = 'investigating',
  Monitoring = 'monitoring',
  Postmortem = 'postmortem',
  Resolved = 'resolved',
}

interface Component {
  created_at: string;
  description: string | null;
  group: boolean;
  group_id: string;
  id: string;
  name: string;
  only_show_if_degraded: boolean;
  page_id: string;
  position: number;
  showcase: boolean;
  start_date: string;
  status: string;
  updated_at: string;
}

interface AffectedComponent {
  code: string;
  name: string;
  new_status: string;
  old_status: string;
}

export interface Incident {
  components: Component[];
  created_at: string;
  id: string;
  impact: IncidentImpact;
  incident_updates: IncidentUpdate[];
  monitoring_at: string | null;
  name: string;
  page_id: string;
  resolved_at: string | null;
  shortlink: string;
  started_at: string;
  status: IncidentStatus;
  updated_at: string;
}

interface IncidentUpdate {
  affected_components: AffectedComponent[];
  body: string;
  created_at: string;
  delivery_notifications: boolean;
  display_at: string;
  id: string;
  incident_id: string;
  status: IncidentStatus;
  tweet_id: string | null;
  updated_at: string;
}

export type IncidentsResponse = Page & Incidents;
